import React,{useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { get_today_date,checkPasswordStrength } from '../Functions/admin_functions';
import Alert_Modals from './Alert_Modals';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import api from '../API/api';
import { check_email } from '../Functions/api_functions';
import SimpleBackdrop from './SimpleBackdrop';

function Add_admin_modal({modal_status,Modal_toggle}) {
    //for toogling the modal
    const toggle = () => Modal_toggle();
    const navigate= useNavigate();
    const [disable,setDisable]=useState(false);
    const [password_alert_dialog,setAlert]=useState(false);
    const [psw_text,setPsw_text]=useState("");
    const [psw_type,setPsw_type]=useState("");
    const alertModals= Alert_Modals();
    const [isloading,setloading]=useState(false);
    const [pwsvisisble,SetVisisble]=useState(false);
    const [user_info,setInfo]=useState({
        firstname:"",
        lastname:"",
        gender:"",
        email:"",
        phonenumber:"",
        role:"",
        password:"",
        status:"Active",
        start_date:get_today_date()
  
    })
       //for handiling password visiblity
  const togglePassword=()=>{
    SetVisisble(!pwsvisisble);
  }
  //for handling input change
  const onHandelChange=(e)=>{
  if (e.target.name=="password")
      {
    const result = checkPasswordStrength(e.target.value);
          if( !result.status)
          {
            setAlert(true);
            setPsw_text(result.message);
            setPsw_type("error");
            setDisable(true);
          }
          else{
            setAlert(true);
            setPsw_text(result.message);
            setPsw_type("success");
            setDisable(false);
            
          }
  
  
      } 
  else if(e.target.name=="email")
    {
      check_email(e.target.value).then((res)=>{
        if(res.status=="success"){
          if(!res.check){
            alertModals.showAlert(" Email is already taken", "error")
            .then(() => {
              setDisable(true);
              // Reset state or perform actions after alert is closed
            });

           
          }
          else{
            setDisable(false);
          }
        }
        
      })
    }   
        setInfo( (prev)=>({
            ...prev,
            [e.target.name]:e.target.value
          }))  
    }
    //for handling form submition
     //for handling the form
     const onHandelSubmit=async(e)=>
        {
            e.preventDefault()
            try { 
              setloading(true);
                const response= await api.post("/Admin/create-account",
                    {
                        user_info
                    }
                );
                if(response.data.status=="success" ){
                  alertModals.showAlert(" Account Created successfuly", "success")
                  .then(() => {
                    setInfo(
                      {
                        firstname:"",
                        lastname:"",
                        gender:"",
                        email:"",
                        phonenumber:"",
                        role:"",
                        password:"",
                        status:"Active",
                        start_date:get_today_date()
                      }
                      
                    )
                    navigate("/Admin/Manage-Admin");
                    // Reset state or perform actions after alert is closed
                  });
                  
              }
            } catch (error) {
              console.log(error);
        alertModals.showAlert(error.message,"error").then(()=>{});
            }finally{
              setloading(false);
            }
        }
  return (
    <div className='container-fluid'>
       <Modal  className='admin_color rounded' isOpen={modal_status} size="xl" toggle={toggle}>
        
        <ModalHeader className='admin_color text-center w-100 ' toggle={toggle}>
        <h2 className='w-100 text-center '> Create new User</h2>
        </ModalHeader>
        <ModalBody className='admin_color'>
          {isloading && <SimpleBackdrop/>}
            <div className='container-fluid'>
            <div className='row'>
            <div className='col-sm-12 col-lg-8 mx-auto'>
            <form onSubmit={onHandelSubmit}>
            

      
                <div className="row">
    <div className="col-sm-12 ">
            <div className="form-floating mb-3 mt-3">
        <input type="text" className="form-control" onChange={onHandelChange} required value={user_info.firstname}id="firstname" placeholder="Enter first name" name="firstname"/>
        <label htmlFor="firstname">Full Name</label>
        </div>    
    </div>
 
  </div>

  <div className="row">
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mt-3 mb-3">
      <input type="email" className="form-control"  value={user_info.email} onChange={onHandelChange} required id="email" placeholder="Enter email" name="email"/>
      <label htmlFor="email">Email</label>
    </div>
        </div>
        <div className="col-sm-12 col-lg-6">
        <div class="input-group mt-3 mb-3">
     <span class="input-group-text  fw-bold
     ">+251</span>
    
          <input type="text" className="form-control p-3" value={user_info.phonenumber} onChange={onHandelChange} required id="phonenumber" placeholder="phonenumber" name="phonenumber"/>
     
    </div>
    </div>
</div>

    <div className='row'>
      <div className='col-sm-12'>
      <div className="input-group form-floating mt-3 mb-3 ">
          <input type={pwsvisisble?"text":"password"} className="form-control" value={user_info.password} onChange={onHandelChange} required id="pwd" placeholder="Enter password" name="password"/>
          <label htmlFor="pwd">Password</label>
          <span className="input-group-text" onClick={togglePassword}><VisibilityIcon/></span>
        </div>
        {password_alert_dialog && <h6 className={psw_type=="success"?" p-1 text-success":"p-1 text-danger"}>{psw_text}</h6>}

      </div>
    </div>
 
    <div className="row">
      <div className="col-sm-12 col-lg-6">
      <div className="form-floating mb-3 mt-3">
          <select className="form-control" onChange={onHandelChange} required value={user_info.gender}name='gender'>
             <option value="">please select Gender</option>
              <option value="Male">Male</option>
              <option value="female">Female</option>
          </select>
          <label htmlFor="Gender">Gender</label>
        </div>    
      </div>
    <div className="col-sm-12 col-lg-6">
      <div className="form-floating mb-3 mt-3">
      <select className="form-control" onChange={onHandelChange} required value={user_info.role} name='role'>
                <option value="">---please select role---</option>
                <option value="admin">Admin</option>
                <option value="reception">Reception</option>
             
            </select>
            <label htmlFor="title">Role</label>
      </div>
    </div>
  </div> 

   

       <div className='row p-3'>
           <button type='submit' disabled={disable}  className='btn btn-success fw-bold' >
                       Submit
            </button>
        </div>
                </form>
            </div>
        </div> 
            </div>
        </ModalBody>
        <ModalFooter className='admin_color'>

<Button color="secondary" onClick={toggle}>
Done
</Button>

</ModalFooter>
       </Modal>
    </div>
  )
}

export default Add_admin_modal