import React,{useEffect, useState} from 'react'
import Alert_Modals from './Alert_Modals';
import SimpleBackdrop from './SimpleBackdrop';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FaUserDoctor } from "react-icons/fa6";
import { validateEthiopianPhoneNumber } from '../Functions/admin_functions';
import api from '../API/api';

function Add_doctors_modal({modal_status,Modal_toggle}){
    const alertModals= Alert_Modals();
    const [isloading,setloading]=useState(false);
    const [disable,setDisable]=useState(false);

    const [user_info,setInfo]=useState({
        fullname:"",
        gender:"",
        email:"",
        phonenumber:"",
        status:"Active",
 
  
    })
      //for handling input change
  const onHandelChange=(e)=>{
     setInfo( (prev)=>({
              ...prev,
              [e.target.name]:e.target.value
            }))  
      }
    //for toogling the modal
 const toggle = () => Modal_toggle(); 
  //for handling form submition
     //for handling the form
     const onHandelSubmit=async(e)=>
        {
            e.preventDefault();
     
   
            const value=validateEthiopianPhoneNumber(user_info.phonenumber);
            if(!value.isValid){
                alertModals.showAlert(value.message, "error")
                .then(() => {
                  // Reset state or perform actions after alert is closed
                });
            }
            else{

                setloading(true);
                try {
                    const response= await api.post("/Doctors/Create",{
                        user_info
                    });
                    if(response.data.status=="success"){
                        alertModals.showAlert("New Doctor Created","success").then(()=>{
                            setInfo({
                                fullname:"",
                                gender:"",
                                email:"",
                                phonenumber:"",
                                status:"Active",
 
                            })
                        })
                    }
                    
                } catch (error) {
                    console.log(error);
                    alertModals.showAlert(error.message,"error").then(()=>{});
                }finally{
                    setloading(false);
                }

            }

        }
  return (
    <div className='container-fluid'>
    <Modal  className='admin_color rounded' isOpen={modal_status} size="xl" toggle={toggle}>
     
     <ModalHeader className='admin_color text-center w-100 ' toggle={toggle}>
     <h2 className='w-100 text-center '> Create new Doctor <FaUserDoctor/> </h2>
     </ModalHeader>
     <ModalBody className='admin_color'>
       {isloading && <SimpleBackdrop/>}
         <div className='container-fluid'>
         <div className='row'>
         <div className='col-sm-12 col-lg-8 mx-auto'>
         <form onSubmit={onHandelSubmit}>
             <div className="row">
 <div className="col-sm-12 ">
         <div className="form-floating mb-3 mt-3">
     <input type="text" className="form-control" onChange={onHandelChange} required value={user_info.fullname}id="fullname" placeholder="Enter first name" name="fullname"/>
     <label htmlFor="firstname">Full Name</label>
     </div>    
 </div>

</div>

<div className="row">
     <div className="col-sm-12 col-lg-6">
     <div className="form-floating mt-3 mb-3">
   <input type="email" className="form-control"  value={user_info.email} onChange={onHandelChange}  id="email" placeholder="Enter email" name="email"/>
   <label htmlFor="email">Email</label>
 </div>
     </div>
     <div className="col-sm-12 col-lg-6">
    
     <div class="input-group mt-3 mb-3">
     <span class="input-group-text  fw-bold
     ">+251</span>
    
       <input type="text" className="form-control p-3" value={user_info.phonenumber} onChange={onHandelChange}  id="phonenumber" placeholder="Enter phonenumber" name="phonenumber"/>

 </div>
 </div>
</div>
 <div className="row">
   <div className="col-sm-12 col-lg-12">
   <div className="form-floating mb-3 mt-3">
       <select className="form-control" onChange={onHandelChange} required value={user_info.gender}name='gender'>
          <option value="">please select Gender</option>
           <option value="Male">Male</option>
           <option value="female">Female</option>
       </select>
       <label htmlFor="Gender">Gender</label>
     </div>    
   </div>

</div> 



    <div className='row p-3'>
        <button type='submit' disabled={disable}  className='btn btn-success fw-bold' >
                    Submit
         </button>
     </div>
             </form>
         </div>
     </div> 
         </div>
     </ModalBody>
     <ModalFooter className='admin_color'>

<Button color="secondary" onClick={toggle}>
Done
</Button>

</ModalFooter>
    </Modal>
 </div>
  )
}

export default Add_doctors_modal