import React ,{useState} from 'react'
import MUIDataTable from 'mui-datatables'
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PendingIcon from '@mui/icons-material/Pending';
import CheckIcon from '@mui/icons-material/Check';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from "moment";
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import Alert_Modals from '../Alert_Modals';
import Detail_Cards from './Detail_Cards';
import { Circle, Edit } from '@mui/icons-material';
import api from '../../API/api';
import Swal from 'sweetalert2';
import Appointment_Detailpage from '../Appointment_Detailpage';
import SimpleBackdrop from '../SimpleBackdrop';
function Appointment_View({result,refresh_data}) {
    var pending =0;
    var canceled=0;
    var confirmed=0;
    var completed=0;
    const [isloading,setloading]=useState(false);
    var total_booking=result.length;
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const [Viewmodal, setModal ] = useState(false);
    const [selectedAppointment,setAppointment]=useState();
    const alertModals= Alert_Modals();
    const options = {
        filterType: 'checkbox',
        pagination:true,
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,15,20],
        rowHover:true,
        selectableRowsHeader:false,
        selectableRows:'none',
       
      };
      const Modal_toggle=()=>{
        setModal(!Viewmodal);
      }
      const handel_action= async(id,status)=>{
        const textvalue= status=="confirmed"?"Confirm":"Cancel"
        Swal.fire({
            title: "Are you sure?",
            text: `Your about to ${textvalue} an Appointment`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `Yes, ${textvalue} it!`
          }).then(async(result) => {
            if (result.isConfirmed) {

                try {
                        setloading(true);
                    const response= await api.put("/Appointment/Update-status",
                        {
                             id,
                         status
                        }
                    );
                    if(response.data.status=="success")
                        {
                            alertModals.showAlert( `Appointment ${status}  Email ${response.data.email_status=="success"?"sent":"failed"}`, "success")
                            .then(() => {
                                refresh_data()
                              // Reset state or perform actions after alert is closed
                            });
                        }
                      
                      
                    
                   } catch (error) {
                    console.log(error);
                    alertModals.showAlert(error.message,"error").then(()=>{
              
                    });
                   } finally{
                    setloading(false);
                   }
            }})
  
      }
      const handel_view= async(id)=>{
        const [appointment]= result.filter((data)=>data.appointment_id==id);
    
        setAppointment(appointment);
        Modal_toggle();
      }
     
   
      const columns = [
        {label:"ID",name:"appointment_id" , options:{filter:false,
            customBodyRender:(value)=>{
                return(
                    <h6 className='p-0'>{value}</h6>
                )
            }
        }},
       
        {label:"Name",name:"name" , options:{filter:false,
            customBodyRender:(value)=>{
                return(
                    <h6 className='p-0'>{value}</h6>
                )
            }
        }},
        {label:"Email",name:"email", options:{filter:false,
            customBodyRender:(value)=>{
                return(
                    <h6 className='p-0'>{value} </h6>
                )
            }
        }},
        {label:"Phone",name:"phone", options:{filter:false,
            customBodyRender:(value)=>{
                return(
                    <h6 className='p-0'>{value} </h6>
                )
            }
        }},
        {label:"Treatment",name:"treatments", options:{filter:false,
            customBodyRender:(value)=>{
                return(
                    <h6 className='p-0'>{value} </h6>
                )
            }
        }},
        {label:"Appointment Time",name:"appointment_date",options: {
            filter: false,
            customBodyRender:(value,tableMeta)=>{
                return(
                   <h5> <span className="badge bg-success text-light p-2 rounded fw-bold">{moment(value, "YYYY-MM-DD hh:mm A").format('MMMM Do YYYY, h:mm A')} </span></h5>
                )
            }
            
           }}
       
        ,
        {label:"Duration",name:"total_duration",options: {
          
            customBodyRender:(value,tableMeta)=>{
                return(
                   <p> {value}</p>
                )
            }
            
           }},
        
        {label:"Status",name:"status",options:{
            customBodyRender:(value)=>{
           
                if(value=="pending")
                { return (
                    <span className=' btn btn-warning  p-1  text-white rounded fw-bold '>{value}</span>
                )}
                else if (value=="canceled")
                {   
                    return (
                        <span className='btn btn-danger text-white p-1 rounded fw-bold'>{value}</span>
                    )
                }
                else if (value=="confirmed")
                    {
                        return (
                            <span className='btn btn-primary text-white p-1 rounded fw-bold'>{value}</span>
                        )
                    }
                    else if (value=="completed")
                        {
                            return (
                                <span className='btn btn-success text-white p-1 rounded fw-bold'>{value}</span>
                            )
                        }
            }
        }},
        {label:"created at",name:"created_at",
            options:{filter:false,
                customBodyRender:(value)=>(
                    moment(value ).format('YYYY-MM-DD')
                ) }
        },
        {label:"Action",name:"appointment_id", options:{filter:false,
                customBodyRender:(value,tableMeta)=>{
                   
                     return(  <>
                        <span className='btn btn-primary mx-1 my-1'onClick ={()=>handel_view(value)}>View<ViewTimelineIcon className=' btn-primary'/></span >
                        <button  className=' btn btn-success rounded mx-1 my-1' disabled={(tableMeta.rowData[7]=="completed"||tableMeta.rowData[7]=="confirmed")?true:false}  onClick ={()=>handel_action(value,"confirmed")}>Confirm<CheckIcon  className=' btn-success '/></button >
                        <button  className='btn btn-danger mx-1 my-1' disabled={tableMeta.rowData[7]=="completed"?true:false} onClick ={()=>handel_action(value,"canceled")}>Cancel<CancelIcon className=' btn-danger'/></button >
                       </>
                   
                )
            }
        }},

    ];
    if(  result!== undefined && result !== null )  { 
        console.log(result);
        result.map((data)=>{
            if(data.status=="pending")
                pending=pending+1;
            else if(data.status=="canceled")
                canceled=canceled+1;
            else if(data.status=="confirmed")
                confirmed=confirmed+1;
            else if(data.status=="completed")
                completed=completed+1;

        })
        return (
       <div className='container-fluid p-3'>
        {isloading && <SimpleBackdrop/>}
        <div>
        </div>
               <MUIDataTable 
          title={"Appointments"}
          data={result}
          columns={columns}
          options={options}
          className="text-color"
        />
                     <div className='row mt-4'>
        <div className='col-sm-12 col-md-3 mx-auto' >
            <Detail_Cards title="Pending Appointments" value={pending} Icon={<PendingIcon className='text-warning' fontSize='large'/>}/>
        </div>
        <div className='col-sm-12 col-md-3 mx-auto' >
         <Detail_Cards title="Confirmed Appointments" value={confirmed} Icon={<CheckIcon className='text-primary' fontSize='large'/>}/>

        </div>
        <div className='col-sm-12 col-md-3 mx-auto' >
            <Detail_Cards title="Canceled Appointments" value={canceled} Icon={<CancelPresentationIcon className='text-danger' fontSize='large'/>}/>
        </div>
        <div className='col-sm-12 col-md-3 mx-auto' >
         <Detail_Cards title="Completed Appointments" value={completed} Icon={<DoneAllIcon className='text-success' fontSize='large'/>}/>

        </div>

    </div>
        <Appointment_Detailpage handel_action={handel_action} modal_status={Viewmodal} appointments={selectedAppointment} Modal_toggle={Modal_toggle} refresh_data={refresh_data}/>
       </div>
        )}
        else
        {
          return (
          <></>
           
            )
          }
      }


export default Appointment_View