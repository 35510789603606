import React ,{useState,useEffect} from 'react'
import TitleHeader from '../components/TitleHeader'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { RefreshRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import SimpleBackdrop from '../components/SimpleBackdrop';
import Add_admin_modal from '../components/Add_admin_modal';
import Alert_Modals from '../components/Alert_Modals';
import Admin_view_Table from '../components/views/Admin_view_Table';
import { get_all_adminside_accounts } from '../Functions/api_functions';
function Admin_management() {
    const [account,setAccount]=  useState((JSON.parse(localStorage.getItem("Admin-Account")))||null);
    const navigate=useNavigate();
    const [AddUserModal, setModal ] = useState(false);
    const [allUser,setUsers]=useState(null);
    const alertModals= Alert_Modals();
  ;
    //function that calls an api function to get all the user data
    const get_all_user_data=()=>{
        get_all_adminside_accounts().then((res)=>{
            if(res.status=="success")
            {
                setUsers(res.accounts)
            }
            else{
                setUsers([]);
               
                alertModals.showAlert("Error while featching Data please try again later ", "error")
                .then(() => {
                    refresh_data()
                  // Reset state or perform actions after alert is closed
                });
            }
        })
    }
    //toggling add user modal
    const toogleModal=()=>
        {
          setModal(!AddUserModal);
         
        }

        // function to referesh the data in the table
        const refresh_data=()=>{
          
                setUsers(null);
                get_all_user_data();
        }
        useEffect(()=>{
            if(account==null){
                navigate("/");
            }
            get_all_user_data();

        },[])

  return (
    <div className='container-fluid '>
        <div className='row'>
            <div className='col-sm-12  whole_title_container shadow'>
                <TitleHeader icon={<PersonAddAltIcon/>} title="Admin Management"/>
            </div>
            <div className='col-sm-12 admin_color mt-5 p-3 rounded'>
                    <div className='row'>

                        <div className='col-sm-6'>
                            
                        </div>
                        <div className='col-sm-6  p-2 d-flex justify-content-end'>
                            <button onClick={toogleModal} className='btn btn-primary mx-2' style={{maxHeight:"70px"}}> Add User <PersonAddAltIcon/> </button>
                            <button className='btn btn-info mx-2'onClick={refresh_data }style={{maxHeight:"70px"}}>Refresh <RefreshRounded/> </button>

                        </div>
                            <div className='col-sm-12'>

                             {  allUser? <Admin_view_Table refresh_data={refresh_data} result={allUser}/>:<SimpleBackdrop/>}
                            </div>
                    </div>
            </div>
        </div>
        <Add_admin_modal modal_status={AddUserModal} Modal_toggle={toogleModal} />
    </div>
  )
}

export default Admin_management