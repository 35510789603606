import * as ac_types from "./Actiontypes"
export const initialState=
{
   user_account:null,
   selected_user:null,
   selected_treatment:null

    
   
 
};
export const Admin_reducer =(state=initialState,action)=>{
    switch(action.type){
        //setting user on login in
        case ac_types.set_user:
            {
               
            return{
                ...state,
                user_account:action.payload
            }
        }
        // setting selected user on edit
        case ac_types.selected_user:
            {
               
            return{
                ...state,
                selected_user:action.payload
            }
        }            

        //resting user on logout
        case ac_types.rest_user:
            return{
                ...state,
                user_account:null,
                selected_user:null,
              
            }
            // seting selected treatment from admin
            case ac_types.selected_treatment:
              {  
                return{
                    ...state,
                    selected_treatment:action.payload
                }}
        default:
            return state;
    }
}