import React, { useEffect, useState } from 'react'
import  "../components/views/Detail_card.css"
import ListIcon from '@mui/icons-material/List';

function Admin_detail_cards({title,Icon,time ,Data}) {
  //  console.log(Data)
   const [data,setData]=useState(Data)
   const [times ,setTime]=useState(time);
  
  useEffect(()=>{

  },[Data])
    return (
      <div className="card_container h-100 ">

      <div className="card w-auto h-100"  >
          <div className="card-body h-100  ">
     
              <div className='text_color'>
                  <h5 className=''>{title} </h5>
              </div>
              <div className='d-flex text_color justify-content-center mt-3'>
                  <h5 className=''> {Icon}</h5>
                  <h5 className={data<0?" text-danger px-3 fw-bold":"px-3 fw-bold"}> | {Data}</h5>
                 
              </div>
              <h6 className='text-secondary p-0 text-end'>{time}</h6>
          </div>
      </div>
      </div>
  
    )
}

export default Admin_detail_cards