// Alert_Modals.js
import Swal from 'sweetalert2';

function Alert_Modals() {
  // Function to display the alert based on the type
  const showAlert = (text, type) => {
    if (type === "error") {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: text,
        confirmButtonColor: '#d33',
        background: '#fff5f5',
        showConfirmButton: true,
      });
    } else if (type === "success") {
      return Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: text,
        confirmButtonColor: '#28a745',
        background: '#f0fff4',
        showConfirmButton: false,
       timer:1200
      });
    } else if (type === "warning") {
      return Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: text,
        confirmButtonColor: '#ffc107',
        background: '#fffaf0',
        showConfirmButton: false,
       timer:2500
      });
    }
    // else if(type==="question"){
    //   Swal.fire({
    //     title: "Are you sure?",
    //     text: text,
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Yes"
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //      return(result.isConfirmed);
    //     }
    //   });
    // }
  };

  // Return the function to be used in the parent component
  return { showAlert };
}

export default Alert_Modals;
