import React ,{useState} from 'react'
import MUIDataTable from 'mui-datatables'
import Alert_Modals from '../Alert_Modals';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import PaidIcon from '@mui/icons-material/Paid';
import Detail_Cards from './Detail_Cards'
import moment from "moment";
function Transaction_view({result,refresh_data}) {
    var sales=0;
    var count=result.length;
    const nf = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      const options = {
        filterType: 'checkbox',
        pagination:true,
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,15,20],
        rowHover:true,
        selectableRowsHeader:false,
        selectableRows:'none'
      };
      const columns = [
       
        {label:"Appointment Id",name:"appointment_id"},
        {label:"Price",name:"amount",options: {
            filter: false,
            customBodyRender:(value,tableMeta)=>{
                return(
                   <h5> <span className="badge bg-success text-light p-2 rounded fw-bold">{nf.format(value)} </span></h5>
                )
            }
            
           }},
           {label:"Payment method",name:"payment_method",options:{
            customBodyRender:(value)=>{
                if(value=="cash")
                { return (
                    <span className=' btn btn-info  p-1  text-white rounded fw-bold '>{value}</span>
                )}
                else if(value=="mobile_payment")
                {
                    return (
                        <span className='btn btn-primary text-white p-1 rounded fw-bold'>{value}</span>
                    )
                }
            }
        }},
        {label:"Payment status",name:"payment_status",options:{
            customBodyRender:(value)=>{
                if(value=="paid")
                { return (
                    <span className=' btn btn-success  p-1  text-white rounded fw-bold '>{value}</span>
                )}
                else if (value=="pending")
                {
                    return (
                        <span className='btn btn-danger text-white p-1 rounded fw-bold'>{value}</span>
                    )
                }
            }
        }},
        {label:"Process Date",name:"transaction_date",
            options:{
                filter:false,
                customBodyRender:(value)=>(
                    <h5> <span className="badge bg-secondary text-light p-2 rounded fw-bold">{moment(value, "YYYY-MM-DD hh:mm A").format('MMMM Do YYYY, h:mm A')} </span></h5>
                ) }
        },
        {label:"Processed By",name:"name"}
        
    ]
      if(  result!== undefined && result !== null )  { 
        result && result.map((data)=>{
            sales=sales+parseFloat(data.amount);})
        return (
       <div className='container'>
        <div>
        </div>
               <MUIDataTable 
          title={"Payment Transaction Reports"}
          data={result}
          columns={columns}
          options={options}
          className="text-color"
        />
                <div className='row mt-4'>
        <div className='col-sm-12 col-md-4 mx-auto' >
            <Detail_Cards title="Sales | ETB" value={nf.format(sales)} Icon={<PaidIcon className='text-success' fontSize='large'/>}/>
        </div>
        <div className='col-sm-12 col-md-4 mx-auto' >
         <Detail_Cards title="Transactions" value={count} Icon={<StackedBarChartIcon className='text-primary' fontSize='large'/>}/>

        </div>

    </div>
       </div>
        )}
        else
        {
          return (
          <></>
            )
          }
}

export default Transaction_view