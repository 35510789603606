
import moment from 'moment';
// to get todays date
export const get_today_date =()=>{
    var currentdate = new Date(); 
    var datetime =currentdate.getFullYear() + "-"
    + (currentdate.getMonth()+1)  + "-" 
    + currentdate.getDate();
      return datetime;
   
  }
    //compare password
export const compare_password=(pass1,pass2)=>
    {
      if(pass1==pass2)
      {
        return true
      }
      else{
          return false
      }
    }

//  for checking password strength
export function checkPasswordStrength(password) {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigit = /[0-9]/.test(password);
    const hasSpecialChar = /[\W_]/.test(password);  // \W matches any non-word character, _ is for underscore
  
    let status = true;
    let message = "Password is strong";
  
    if (password.length < minLength) {
        status = false;
        message = "Password must be at least 8 characters long.";
    } else if (!hasUpperCase) {
        status = false;
        message = "Password must contain at least one uppercase letter.";
    } else if (!hasLowerCase) {
        status = false;
        message = "Password must contain at least one lowercase letter.";
    } else if (!hasDigit) {
        status = false;
        message = "Password must contain at least one numerical digit.";
    }
  
    return {
        status: status,
        message: message
    };
  }


export function validateEthiopianPhoneNumber(phoneNumber)
 {
    // Define the regular expression for the specific format: 9XXXXXXXX
    const pattern = /^[79]\d{8}$/;
  
    // Check if the phone number matches the pattern
    if (pattern.test(phoneNumber)) {
        return { isValid: true, message: "Valid Ethiopian phone number." };
    }
  
    // Determine the error reason if the phone number is invalid
    if (!phoneNumber.startsWith('7') && !phoneNumber.startsWith('9')) {
      return { isValid: false, message: "Phone number must start with '7' or '9'." };
  }
    if (phoneNumber.length !== 9) {
        return { isValid: false, message: "Phone number must have exactly 9 digits after the leading '9'." };
    }
  
    return { isValid: false, message: "Invalid phone number format." };
  }

// Helper function to check for conflicting appointments
const isConflictingAppointment = (currentSlot, appointment) => {
  const appointmentStart = moment(appointment.appointment_date, "YYYY-MM-DD hh:mm A").local();
  const appointmentEnd = appointmentStart.clone().add(appointment.total_duration, 'minutes');

  console.log("Current Slot:", currentSlot.format());
  console.log("Appointment Start:", appointmentStart.format());
  console.log("Appointment End:", appointmentEnd.format());

  const isBetween = currentSlot.isBetween(appointmentStart, appointmentEnd, undefined, '[)');
  console.log("Is between:", isBetween);

  return isBetween;
};


// Main function to generate time options
export const generateTimeOptions = (selectedDate, existingAppointments) => {
  const workingHours = {
    0: { start: 11, end: 15 }, // Sunday
    1: { start: 9, end: 18 },  // Monday
    2: { start: 9, end: 18 },  // Tuesday
    3: { start: 9, end: 18 },  // Wednesday
    4: { start: 9, end: 18 },  // Thursday
    5: { start: 9, end: 18 },  // Friday
    6: { start: 9, end: 16 }   // Saturday
  };

  selectedDate = moment(selectedDate).local();  // Parse selected date as local time
  console.log("Selected Date (Local):", selectedDate.format());
  console.log("Existing Appointments:", existingAppointments); // Debug existing appointments

  const options = [];
  const now = moment().local(); // Set current time as local
  const selectedDay = selectedDate.day();
  const { start, end } = workingHours[selectedDay];

  let current = selectedDate.clone().hours(start).minutes(0).seconds(0);

  console.log("Start of working hours:", current.format());
  console.log("Now:", now.format());

  // Generate time slots with 30-minute intervals
  while (current.hours() < end || (current.hours() === end && current.minutes() === 0)) {
    const hours24 = current.hours();
    const minutes = current.minutes().toString().padStart(2, '0');
    const period = hours24 >= 12 ? 'PM' : 'AM';
    const hours12 = hours24 % 12 || 12;
    const formattedTime = `${hours12}:${minutes} ${period}`;

    if (selectedDate.isSame(now, 'day') && current.isBefore(now)) {
      current.add(30, 'minutes');
      continue;
    }

    console.log("Checking for conflicts at:", formattedTime);

    const conflict = existingAppointments && existingAppointments.some(appointment => {
      console.log("Evaluating appointment:", appointment); // Log each appointment
      return isConflictingAppointment(current, appointment);
    });

    if (!conflict) {
      options.push(formattedTime);
    }

    current.add(30, 'minutes');
  }

  console.log("Final available slots:", options);
  return options;
};

