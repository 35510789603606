import React,{useEffect, useState} from 'react'
import Alert_Modals from './Alert_Modals';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import api from '../API/api';
import { get_all_treatments } from '../Functions/api_functions';
function AddExtra_modal({refresh_data,modal_status,Modal_toggle,appointment_id,bookedTreatments,reload }) {
    const [treatments,setTreatments]=useState();
    const alertModals= Alert_Modals();
    const toggle=()=>Modal_toggle();
    const fetch_data=()=>{
        get_all_treatments().then((res)=>{
          if(res.status=="success"){
            const bookedtreatmentIDs= new Set(bookedTreatments.map(data=>data.id));
            const availableTreatments= res.treatments.filter(data=>!bookedtreatmentIDs.has(data.id));
            setTreatments(availableTreatments);
            console.log(availableTreatments);
          }
        })
        
      }
      const [formData,setFormData]=useState({
        treatments:[],
        appointment_id
    });
    //handel change of the selection
    const onHandelChange=(e)=>{
        if(e.target.name=="treatments")
        {
            const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
          
            setFormData( (prev)=>({
                ...prev,
                [e.target.name]:selectedOptions
                })) 
                if(e.target.value=='')
                {
                    setFormData( (prev)=>({
                        ...prev,
                        [e.target.name]:[]
                        })) 
                } 
        }}
        const onHandelSubmit=async(e)=>{
            e.preventDefault();
            console.log(formData)
            try {
                const response= await api.post("/Appointment/add-extra-treatment",{
                    treatments:formData.treatments,
                    appointment_id
                })
                if(response.data.status=="success"){
                    alertModals.showAlert("Treatment Added","success").then(()=>{
                        reload();
                        toggle()
                    });
                }
                
            } catch (error) {
                console.log(error);
                alertModals.showAlert(error.message,"error").then(()=>{});
            }
        }
        useEffect(()=>{
           fetch_data(); 
        },[bookedTreatments])
  return (
    <div className='container-fluid'>
    <div className='row'>
    
       
        <div className='col-sm-12 '>
        <Modal  className='admin_color rounded' isOpen={modal_status} size="md" toggle={toggle}>
    <ModalHeader className='admin_color text-center w-100 ' toggle={toggle}>
    <h2 className='w-100 text-center '> Add Extra Treatment</h2>
    </ModalHeader>
    <ModalBody className='admin_color'>
        <div className='container-fluid'>
        <div className='row'>
      <form onSubmit={onHandelSubmit}>
      <div className='row w-100 p-2'  style={{width:"100%"}}>
   <div className='col-sm-12 w-100'>
   <div className=" mb-3 mt-3">
       <select className="form-select mt-2 p-1" style={{width:"100%"}} required multiple onChange={onHandelChange}  value={formData.treatments} name='treatments'>
       <option value="">please select Treatment</option>
       {
        treatments && treatments.map((data, index)=>{
         if( data.status=="inactive"){
          return (
            <option className='border border-1 my-1 selection_option text-secondary' key={index} disabled="true
            
            " value={data.id} > {data.name} (Comming Soon) </option>
          )
         }
         
         else{
           return(
            <option  className='border border-1 my-1 selection_option' key={index} value={data.id} > {data.name} </option>
          )
        }

        })
       }
         
       </select>
       
     </div> 
   </div>
 </div>
 <div className='row p-3'>
           <button type='submit'  className='btn btn-success fw-bold' >
                       Add
            </button>
        </div>
      </form>
    </div> 
        </div>
    </ModalBody>
    <ModalFooter className='admin_color'>

<Button color="secondary" onClick={toggle}>
Done
</Button>

</ModalFooter>
   </Modal>
        </div>
    </div>
   
</div>
  )
}

export default AddExtra_modal