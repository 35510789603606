import React,{useEffect, useState} from 'react'
import Alert_Modals from './Alert_Modals';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import api from '../API/api';
import { useNavigate } from 'react-router-dom';
import SimpleBackdrop from './SimpleBackdrop';
function Add_treatment_modal({modal_status,Modal_toggle,refresh}) {
//for toogling the modal
const toggle = () => Modal_toggle();
const navigate= useNavigate();
    const [disable,setDisable]=useState(false);
    const alertModals= Alert_Modals();
    const [isloading,setloading]=useState(false);
    const [formData,setData]=useState({
        name:"",
        duration:"",
        price:"",
        image:null,
        status:"",
        description:""
    })
    const[treatmentImage,setImage]=useState()
    // input handel change
    const onHandelChange =(e)=>{
        if(e.target.name=="image")
        {
          const files=e.target.files[0];
          if (files.size<=2097152)
          {
            setDisable(false)
            setData( (prev)=>({
           ...prev,
           [e.target.name]:files
         }))
         setImage(files);
      }else{
        alertModals.showAlert("File size is to large ", "error")
        .then(() => {
          setDisable(true)
          // Reset state or perform actions after alert is closed
        });
      
      }
        }
       
        else
        {
            setData( (prev)=>({
           ...prev,
           [e.target.name]:e.target.value
         }))
        }
       }
    // for handling form submition
    const onHandelSubmit=async(e)=>{
        e.preventDefault()
        console.log(formData);
        const data= new FormData();
        for ( var key in formData ) {
          
        
             data.append(key, formData[key]);
        }
        try {
          setloading(true);
            const response= await api.post("/Treatment/Create/treatment",data,{headers:{"Content-Type":"multipart/form-data"}});
            if(response.data.status=="success")
            {   
             
                     alertModals.showAlert(" New treatment uploaded successfully", "success")
                     .then(() => {
                      setData({
                        name:"",
                        duration:"",
                        price:"",
                        image:null,
                        status:"",
                        description:""
                       });
                       refresh();
                       toggle();
                       // Reset state or perform actions after alert is closed
                     });
            }
            
        } catch (error) {
          console.log(error);
          alertModals.showAlert(error.message,"error").then(()=>{});
        }finally{
          setloading(false);
        }
    }
  return (
    <div className='container-fluid'>
        <div className='row'>
          {isloading && <SimpleBackdrop/>}
           
            <div className='col-sm-12 '>
            <Modal  className='admin_color rounded' isOpen={modal_status} size="xl" toggle={toggle}>
        <ModalHeader className='admin_color text-center w-100 ' toggle={toggle}>
        <h2 className='w-100 text-center '> Add new treatment</h2>
        </ModalHeader>
        <ModalBody className='admin_color'>
            <div className='container-fluid'>
            <div className='row'>
            <div className='col-sm-12 col-lg-5 d-flex justify-content-center p-3'>
{            treatmentImage && <img src={URL.createObjectURL(treatmentImage)}alt='treatment-image' className='img-fluid mx-auto rounded ' style={{minHeight:"400px",maxHeight:"450px",border:"0.2px solid white"}}/>}
            </div>
            <div className='col-sm-12 col-lg-7 mx-auto'>
            <form onSubmit={onHandelSubmit}>
            

      
                <div className="row">
    <div className="col-sm-12 ">
            <div className="form-floating mb-3 mt-3">
        <input type="text" className="form-control" onChange={onHandelChange} required value={formData.name}id="name" name="name"/>
        <label htmlFor="firstname">Treatment Name</label>
        </div>    
    </div>
 
  </div>

  <div className="row">
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mb-3 mt-3">
      <select className="form-control" onChange={onHandelChange} required value={formData.duration} name='duration'>
                <option value="">---please select duration---</option>
                <option value="15">15 Min</option>
                <option value="20">20 Min</option>
                <option value="30">30 Min</option>
                <option value="45">45 Min</option>
                <option value="60">60 Min</option>
                <option value="90">90 Min</option>
                <option value="120">120 Min</option>
             
            </select>
            <label htmlFor="title">Duration</label>
      </div>
        </div>
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mt-3 mb-3">
          <input type="number" className="form-control" value={formData.price} onChange={onHandelChange} required id="price"  name="price"/>
      <label htmlFor="phonenumber">Price</label>
    </div>
    </div>
</div>

    <div className='row'>
      <div className='col-sm-12'>
      <label htmlFor="pwd" className='fw-bold'>Image</label>
      <div className="  mt-3 mb-3 ">
          <input type="file" className="form-control"  onChange={onHandelChange} required id="image" placeholder="image" name="image"/>
          
        </div>
       

      </div>
      
    </div>
    <div className='row'>
    <div className="col-sm-12 ">
      <div className="form-floating mb-3 mt-3">
      <select className="form-control" onChange={onHandelChange} required value={formData.status} name='status'>
                <option value="">---please select status---</option>
                <option value="active">active</option>
                <option value="inacitve">Coming soon</option>
             
            </select>
            <label htmlFor="title">Status</label>
      </div>
    </div>

    </div>
 
    <div className="row">
    <div className='col-sm-12 '>
   <div className="form-floating mb-3 mt-3">
  
      <textarea className='form-control' style={{height:"150px"}} required value={formData.description} onChange={onHandelChange} name='description'/>
      <label htmlFor="Gender">Treatment Description</label>
     </div> 
   </div>
  </div> 

   

       <div className='row p-3'>
           <button type='submit' disabled={disable} className='btn btn-success fw-bold' >
                       Submit
            </button>
        </div>
                </form>
            </div>
        </div> 
            </div>
        </ModalBody>
        <ModalFooter className='admin_color'>

<Button color="secondary" onClick={toggle}>
Done
</Button>

</ModalFooter>
       </Modal>
            </div>
        </div>
       
    </div>
  )
}

export default Add_treatment_modal