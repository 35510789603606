import React,{useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { get_today_date, validateEthiopianPhoneNumber } from '../Functions/admin_functions';
import Alert_Modals from './Alert_Modals';
import moment from 'moment';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import api from '../API/api';
import './Default.css'
import { generateTimeOptions } from '../Functions/admin_functions';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { get_all_doctors, get_all_treatments, get_selectedDate_appointment } from '../Functions/api_functions';
import SimpleBackdrop from './SimpleBackdrop';
function Add_appointment({modal_status,Modal_toggle,refresh_data}) {
    //for toogling the modal
    const toggle = () => Modal_toggle();
      // Generate time options based on selected date
  const [timeOptions, setTimeOptions] = useState([]);
    const navigate= useNavigate();
    const [allTreatments,setTreatments]=useState(null);
    const alertModals= Alert_Modals();
    const [appointments,setAppointment]=useState();
    const [isloading,setloading]=useState(false);
    const [doctors,setdoctors]=useState(null);
    const [formData,setFormData]=useState({
        name:"",
        email:"",
        phonenumber:"",
        appointment_Date:"",
        appointment_Starttime:"",
        doctor:"",
        treatments:[],
        portal:"",
        request_message:"",
        created_date:get_today_date()
  
    })
    // handel date change and set the time
    const onhadelDate=(date)=>{
      const selectedDate = moment(date).local();
            setFormData( (prev)=>({
                ...prev,
                appointment_Date:moment(date).format('YYYY-MM-DD')
                }))
             
               get_selectedDate_appointment(moment(date).format('YYYY-MM-DD')).then((res)=>{
                  
                  setAppointment(res.appointments);
                  console.log(res.appointments);
                  setTimeOptions(generateTimeOptions(selectedDate,res.appointments));
                  
                })
        }
    
    //  handel input change in the form
    const onHandelChange=(e)=>{
        if(e.target.name=="treatments")
        {
            const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
           setFormData( (prev)=>({
                ...prev,
                [e.target.name]:selectedOptions
                })) 

        }
      
       else {
           setFormData( (prev)=>({
        ...prev,
        [e.target.name]:e.target.value
        })) 
     }

    }
     // Handle form submission (to be connected to backend)
  const onHandelSubmit = async (e) => {
    e.preventDefault();
    console.log(formData); // This is where you'd send the data to the backend
    // Send the formData to the backend via an API call
    const value=validateEthiopianPhoneNumber(formData.phonenumber);
    if (!value.isValid){
      alertModals.showAlert(value.message, "error")
      .then(() => {
        // Reset state or perform actions after alert is closed
      });
    }
    else{
      setloading(true);
      try {
        const date=`${formData.appointment_Date} ${formData.appointment_Starttime}`;
        const check= await api.get('/Appointment/check-time',{params:{
          date
        }})
    
        if(check.data.status=="success"&& check.data.result<=0){
          const url=(formData.email!==null&&formData.email!==''?"/Appointment/Create-appointment":"/Appointment/Create-admin-appointments")
          const response= await api.post(url,
            {formData}
          )
          if(response.data.status=="success")
        {  
          alertModals.showAlert(" Appointment Created successfuly", "success")
          .then(() => {
            setFormData({
              name:"",
              email:"",
              phonenumber:"",
              appointment_Date:"",
              appointment_Starttime:"",
              doctor:"",
              treatments:[],
              portal:"",
              request_message:"",
              created_date:get_today_date()
            });
             refresh_data();
            toggle();
           
            // Reset state or perform actions after alert is closed
          });
        
         
        }
        }
        else{
          alertModals.showAlert("Sorry Time slot is already booked please pick another","warning").then(()=>{});
        }
     
      } catch (error) {
        console.log(error);
        alertModals.showAlert(error.message,"error").then(()=>{});
      }finally{
        setloading(false);
      }
    }
   
  };
  // function for fetching data
  const fetch_data=()=>{
    get_all_treatments().then((res)=>{
      if(res.status=="success"){
        setTreatments(res.treatments);
      }
    })
    get_all_doctors().then((res)=>{
      setdoctors(res.doctors);
    })
    
  }
  useEffect(()=>{
    fetch_data();
  },[])
  return (
    <div className='container-fluid'>
    <Modal  className='admin_color rounded' isOpen={modal_status} size="xl" toggle={toggle}>
     <ModalHeader className='admin_color text-center w-100 ' toggle={toggle}>
     <h2 className='w-100 text-center '> Create new Appointment</h2>
     </ModalHeader>
     <ModalBody className='admin_color'>
      {isloading && <SimpleBackdrop/> }
         <div className='container-fluid'>
         <div className='row'>
         <div className='col-sm-12 col-lg-8 mx-auto'>
         <form onSubmit={onHandelSubmit}>
         

   
             <div className="row">
 <div className="col-sm-12 ">
         <div className="form-floating mb-3 mt-3">
     <input type="text" className="form-control" onChange={onHandelChange} required value={formData.name}id="firstname" placeholder="Enter first name" name="name"/>
     <label htmlFor="firstname">Full Name</label>
     </div>    
 </div>

</div>

<div className="row">
     <div className="col-sm-12 col-lg-6">
     <div className="form-floating mt-3 mb-3">
   <input type="email" className="form-control"  value={formData.email} onChange={onHandelChange}  id="email" placeholder="Enter email" name="email"/>
   <label htmlFor="email">Email</label>
 </div>
     </div>
     <div className="col-sm-12 col-lg-6">
     <div class="input-group mt-3 mb-3">
     <span class="input-group-text  fw-bold
     ">+251</span>
    
       <input type="text" className="form-control p-3" value={formData.phonenumber} onChange={onHandelChange} required id="phonenumber" placeholder=" phonenumber" name="phonenumber"/>
   
 </div>
 </div>
</div>

 <div className='row'>
   <div className='col-sm-12'>
   <div className=" mb-3 mt-3">
   <label htmlFor="Gender">Treatments</label>
       <select className="form-select mt-2 p-1" required multiple onChange={onHandelChange}  value={formData.treatments} name='treatments'>
       <option value="">please select Treatment</option>
       {
        allTreatments && allTreatments.map((data, index)=>{
         if( data.status=="inactive"){
          return (
            <option className='border border-1 my-1 selection_option text-secondary' key={index} disabled="true
            
            " value={data.id} > {data.name} (Comming Soon) </option>
          )
         }
         
         else{
           return(
            <option  className='border border-1 my-1 selection_option' key={index} value={data.id} > {data.name} </option>
          )
        }

        })
       }
         
       </select>
       
     </div> 
   </div>
 </div>
 

 <div className="row">
   <div className="col-sm-12 col-lg-6">
   <div className="mb-3 mt-3">
   <label className="" htmlFor="date">
                    Date
                  </label>
                  <MobileDatePicker minDate={moment(get_today_date())} onChange={(newValue) => onhadelDate(newValue)} name='appointment_Date'  className=' mt-1 w-100 p-0 bg-white fw-bold' />

     </div>    
   </div>
 <div className="col-sm-12 col-lg-6">
   <div className="mb-3 mt-3">
   <label htmlFor="title">Time </label>
   <select className="form-control mt-1 p-3" onChange={onHandelChange}  disabled={!formData.appointment_Date}  required value={formData.appointment_Starttime} name='appointment_Starttime'>
   <option value="">Select Time</option>
              {timeOptions.map((time, index) => (
                <option key={index} value={time}>
                  {time}
                </option>
              ))}
          
         </select>
         
   </div>
 </div>
</div> 
<div className='row'>
   <div className='col-sm-12'>
   <div className="form-floating mb-3 mt-3">
       <select className="form-select" required  onChange={onHandelChange}  value={formData.doctor }name='doctor'>
             <option value="">Select Doctor</option>
             {
              doctors && doctors.map((doctor,index)=>{
                return(
                  <option key={index} value={doctor.id}> {doctor.name}</option>
                )
              })
             }
           
       </select>
       <label htmlFor="Gender">Doctor</label>
     </div> 
   </div>
 </div>
 <div className='row'>
   <div className='col-sm-12'>
   <div className="form-floating mb-3 mt-3">
       <select className="form-select" required onChange={onHandelChange}  value={formData.portal }name='portal'>
             <option value="">Select Portal</option>
              <option value="Walkin">Walk in</option>
              <option value="Over-phone">Over phone</option>
       </select>
       <label htmlFor="Gender">Portal</label>
     </div> 
   </div>
 </div>
<div className='row'>
   <div className='col-sm-12 '>
   <div className="form-floating mb-3 mt-3">
  
      <textarea className='form-control' style={{height:"150px"}} value={formData.request_message} onChange={onHandelChange} name='request_message'/>
      <label htmlFor="Gender">Special requests</label>
     </div> 
   </div>
 </div>



    <div className='row p-3'>
        <button type='submit'   className='btn btn-success fw-bold' >
                    Submit
         </button>
     </div>
             </form>
         </div>
     </div> 
         </div>
     </ModalBody>
     <ModalFooter className='admin_color'>

<Button color="secondary" onClick={toggle}>
Done
</Button>

</ModalFooter>
    </Modal>

    </div>
  )
}

export default Add_appointment