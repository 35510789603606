import React ,{useState,useEffect}from 'react'
import Admin_NavItem from './NavbarData';
import "./navbar.css"
import ListIcon from '@mui/icons-material/List';
import { Link, useNavigate } from 'react-router-dom';
function Navbar({children}) 
{
    const [account,setAccount]=  useState((JSON.parse(localStorage.getItem("Admin-Account")))||null);
    const [isopen,SetOpen]=useState(false);
    const navigate=useNavigate();

    const [navBar,setData]=useState(null);
    // function for collapsing navigation bar on the side
    const toggle=()=>{
        SetOpen(!isopen);
    }
    useEffect(()=>{
        if(account==null){
            navigate("/");
        }
        else{
            if(account.role=="admin"){
                setData(Admin_NavItem.filter((data)=>data.role=="Admin"||data.role=="Both"));
            }
            else if (account.role=="reception")
            {
                setData(Admin_NavItem.filter((data)=>data.role=="Both"));

            }
        }
        
    },[])

  return (
    <div className='container-fluid p-0 h-100'>
        <div className='row'>
            <div className='col-sm-12 d-flex w-100'>
            <div className='app_sidebar   ' style={{width:isopen? "250px":"50px"}}>
              <div className='top_section '>
                  <div className='logo container mb-3 ' style={{display:isopen? "block":"none"}}>
                  <img src='../Assets/logo.webp' className='sidebar_logo rounded ' alt=''/> 
                  </div>
                  <div style={{fontSize:isopen? "20px":"40px"}}>
                      <ListIcon onClick={toggle} className='bars'/>
                  </div>
              </div>
            
              <div className='nav_section '>
                 
              {
                 navBar && navBar.map((nav ,index)=>{
                    return(
                        <Link  key= {index} to={nav.path} className='nav_options'>
                    
                        <div className='nav_icons'>
                            {nav.icon}
                        </div>
                        <div className='nav_options_text' style={{display:isopen? "block":"none"}}>
                            <h2> {nav.name} </h2>
                        </div>
                    </Link> 
    
                        )
                  })
              }
                 
                  
            
                
                
              </div>
          </div>
          <div className=' content-container container-fluid' style={{width:isopen? "90%":"98%"}}>
              {children}
            </div>

  
            </div>
        </div>

    </div>
  )
}

export default Navbar