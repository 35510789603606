import api from "../API/api";
import { get_today_date } from "./admin_functions";

//function for getting all the admin side accounts
export const get_all_adminside_accounts=async()=>{
    try {
        const response=await api.get("/Admin/get-all/admin-account");
        return({
            status:"success",
            accounts:response.data.accounts            
        })
    } catch (error) {
        console.log(error);
        return({
            status:"fail",
            message:error.message
        })
    }

}
//  function for getting all the treatments 
export const get_all_treatments= async()=>{
    try {
        const response=await api.get("/Treatment/get-all");
        return({
            status:"success",
            treatments:response.data.treatments            
        })
    } catch (error) {
        console.log(error);
        return({
            status:"fail",
            message:error.message
        })
    }
}
// function for handling any account logout from user Account
export const logout=async()=>{
  
    try {
      await api.get("/Admin/Logout");
  
      // Clear access token from local storage
      localStorage.removeItem('Access-Token');
      localStorage.removeItem('Admin-Account');
  
      // Remove Authorization header
      delete api.defaults.headers.common['Authorization'];
  
      console.log('User logged out');
      window.location.href=("/");
    
    } catch (error) {
      console.error('Error logging out:', error);
    }
  
  
  }
// check if email is taken
  export const check_email= async(value)=>{
    try {
        const response= await api.get(`/Admin/Check-email/${value}`);
        if(response.data.status=="success" && response.data.result>0)
            {
            return(
                {
                    status:"success",
                    check:false,
                }
            ) 
        }
        
        else {
            return(
                {
                    status:"success",
                    check:true,
                }
            )   
        }
        
    } catch (error) {
        return(
            {
                status:"fail",
                message:error.message
            }
        )
    }
  }
  // function for getting all the appointments
  export const get_all_appointments= async()=>{
    try {
        const response=await api.get("/Appointments/get-all");
        return({
            status:"success",
            appointments:response.data.appointments
        })
    } catch (error) {
        console.log(error);
        return({
            status:"fail",
            message:error.message
        })
    }
}
//get all transactions 
export const get_all_transaction= async()=>{
    try {
        const response=await api.get("/Transaction/get-all");
        return({
            status:"success",
            transactions:response.data.transactions
        })
    } catch (error) {
        console.log(error);
        return({
            status:"fail",
            message:error.message
        })
    }
}
// const get all appointments today
export const get_selectedDate_appointment= async(date)=>{
    
    try{
        const response=await api.get(`/Appointment/get-selected-date/${date}`);
        return({
            status:"success",
            appointments:response.data.appointments
        })
    }catch(error){
        console.log(error);
        return({
            status:"fail",
            message:error.message
        });
    }
}
// get treatments with appointment id
export const get_appointment_treatments=async(id)=>{
    try{
        const response=await api.get(`/Appointment/appointments_treatments/${id}`);
        return({
            status:"success",
            appointments_treatments:response.data.appointments_treatments
        })
    }catch(error){
        console.log(error);
        return({
            status:"fail",
            message:error.message
        });
    }

}
// check old password
export const check_oldpassword =async(id,password)=>
    {
        try{
            const response=await api.post(`/Admin/Old-password/check`,{
                id,
                password
            });
            return response.data;
        }catch(error)
        {
            console.log(error);
            return({
                status:"fail",
                error:error.response.data.error
            })
        }
    }
//fetch all doctors
export const get_all_doctors= async()=>{
    try{
        const response =await api.get("/Doctor/get-all");
        return({
            status:"success",
            doctors:response.data.doctors
        })
    }catch(error){
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
}