import React ,{useState} from 'react'
import MUIDataTable from 'mui-datatables'
import { useSelector,useDispatch } from 'react-redux';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import Alert_Modals from '../Alert_Modals';
import { Edit } from '@mui/icons-material';
import api from '../../API/api';
import { set_selected_user } from '../../Store/Action';
import SimpleBackdrop from '../SimpleBackdrop';

function Admin_view_Table ({result,refresh_data}){
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const alertModals= Alert_Modals();
    const [isloading,setloading]=useState(false);
    
    // function for handling deactivation of user account
    const handel_Deactivation=async(id)=>{

        
    try{
        setloading(true)
        const response= await api.put("/Admin/Deactivate/account",{
            id
        })
        if(response.data.status="success" && response.data.result>=1)
        {
           
            alertModals.showAlert(" Account Deactivated successfully", "success")
            .then(() => {
                refresh_data()
              // Reset state or perform actions after alert is closed
            });
          
        }
        else{
            alertModals.showAlert("Error occured Please try again ", "error")
            .then(() => {
                refresh_data()
              // Reset state or perform actions after alert is closed
            });
           
        }

    }catch(error){
        console.log(error);
        alertModals.showAlert(error.message,"error").then(()=>{});
    }finally{
        setloading(false);
    }

    }
    // function for handling edit of user account
    const handel_Edit=(id)=>{
        const [selectedUser]= result.filter((data)=> data.id==id);
        dispatch(set_selected_user(selectedUser));
        navigate(`/Admin/Edit-Admin/${selectedUser.email}`);
    }

    const options = {
        filterType: 'checkbox',
        pagination:true,
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,15,20],
        rowHover:true,
        selectableRowsHeader:false,
        selectableRows:'none'
      };

      const columns = [
       
        {label:"Firstname",name:"name"},
        {label:"Gender",name:"gender"},
        {label:"Phonenumber",name:"phonenumber"},
        {label:"Email",name:"email"},
        {label:"Role",name:"role",options:{
            customBodyRender:(value)=>{
                if(value=="reception")
                { return (
                    <span className=' btn btn-info  p-1  text-white rounded fw-bold '>{value}</span>
                )}
                else if(value=="admin")
                {
                    return (
                        <span className='btn btn-primary text-white p-1 rounded fw-bold'>{value}</span>
                    )
                }
            }
        }},
        
        {label:"Status",name:"status",options:{
            customBodyRender:(value)=>{
                if(value=="active")
                { return (
                    <span className=' btn btn-success  p-1  text-white rounded fw-bold '>{value}</span>
                )}
                else if(value=="inactive")
                {
                    return (
                        <span className='btn btn-danger text-white p-1 rounded fw-bold'>{value}</span>
                    )
                }
                else if(value=="pending")
                    {
                        return (
                            <span className='btn btn-primary text-white p-1 rounded fw-bold'>{value}</span>
                        )
                    }
            }
        }},
        {label:"created at",name:"created_at",
            options:{
                customBodyRender:(value)=>(
                    moment(value ).format('YYYY-MM-DD')
                ) }
        },
        {label:"Action",name:"id", options:{
                customBodyRender:(value)=>(
                   
                       <>
                        <span className=' btn btn-success rounded mx-1 my-1'onClick ={()=>handel_Edit(value)}>Edit<Edit  className=' btn-success '/></span>
                        <span className='btn btn-warning mx-1 my-1'onClick ={()=>handel_Deactivation(value)}>Deactivate <LockIcon  className='btn-danger'/></span>
                       </>
                   
                )
        }},

    ];
    if(  result!== undefined && result !== null )  { 
        return (
       <div className='container'>
       { isloading && <SimpleBackdrop/>}
        <div>
        </div>
               <MUIDataTable 
          title={"User Account report"}
          data={result}
          columns={columns}
          options={options}
          className="text-color"
        />
       </div>
        )}
        else
        {
          return (
          <Alert_Modals type="error" 
          text="Sorry no User found on the system !"
            />
            )
          }
      }

export default Admin_view_Table