import React, { useState } from 'react';
import './Loginpage.css'
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import api from '../API/api.js'
import Alert_Modals from '../components/Alert_Modals'
import { useDispatch } from 'react-redux';
import { set_user_state } from '../Store/Action.js';
import Forgotpassword from '../components/Forgotpassword.js';
import Password_change_modal from '../components/Password_change_modal.js';
function Loginpage() {
   const navigate=useNavigate();
   const dispatch=useDispatch();
   const [account,setAccount]=useState(null);
    const [modal_status,setStatus]=useState(false);
    const [pws_change,setPws_change]=useState(false);
    const alertModals= Alert_Modals();
    const[showpassword,setShow]=useState(false);
    const[user_info,SetInfo]=useState({
        
        email:"",
        password:""
    });

    //toggle forgetpassword modal

    const Modal_toggle=()=>
      {
        setStatus(!modal_status);
      }
      const pws_toogle=()=>{
          setPws_change(!pws_change);
      }
      //toggole  password vissible
     const toogle_password=()=>{
        setShow(!showpassword);
    }
    // for hadeling change in the input field
    const onHandelchange=(e)=>{
        SetInfo( (prev)=>({
            ...prev,
            [e.target.name]:e.target.value
          }));
}
//handel form submit
const onHandelsubmit=async(e)=>{
    console.log(user_info);
    e.preventDefault();
    try {
       const response= await api.post("/Admin/Login",{
        user_info
       })
       if(response.data.status=="success")
       {
            if(response.data.account.status=="inactive")
            {   
              
                  alertModals.showAlert("Sorry your account is inactive please contact your Admin", "error")
                  .then(() => {
                   
                    // Reset state or perform actions after alert is closed
                  });
            }
            else {
                dispatch(set_user_state(response.data.account));
                localStorage.setItem("Access-Token",JSON.stringify(response.data.token));
                localStorage.setItem("Admin-Account",JSON.stringify(response.data.account));
                setAccount(response.data.account);
                if(response.data.account.status=="pending"){
                    pws_toogle();

                }
                  else{
                    alertModals.showAlert("Login Successfull", "success")
                  .then(() => {
                    if(response.data.account.role=='admin'){
                        navigate("/Admin/Dashbord");
                    }
                    else{
                        navigate("/Admin/Manage-appointments")
                    }
                   
                    // Reset state or perform actions after alert is closed
                  });
                  }

            }
            

       }
       else{
        
        alertModals.showAlert(response.data.message,"error").then(()=>{});
       }

    } catch (error) {
        console.log(error);
        alertModals.showAlert(error.response.data.message,"error").then(()=>{});

    }
    
  }

  return (
    <div className='container-fluid p-0 h-100' style={{backgroundColor:"rgb(239, 230, 203)",minHeight:`${window.innerHeight}px`,}}>
        <div className='row'>
            <div className='col-sm-12 col-lg-5 d-none d-lg-block h-100 ' >
                <div className='d-flex w-100  hero-image-container' >
                </div>
            </div>
            <div className='col-sm-12 col-lg-7  '>
                <div className='loginpage-container rounded'>
                     <div className='row '>
                      
            <div className='col-sm-12   h-100 mx-auto'>
                <div className=' admin_login_container shadow rounded'>
                <div className='col-sm-12 p-0'>
                        <div className='  p-0 d-flex justify-content-center'>
                        <img src='../Assets/logo.webp' alt='Logo' className='img-fluid rounded' style={{maxHeight:"80px"}}/>
                         </div>
                         <div className='row  welcome_text'>
                                <h4 className=' w-100  text-center '>
                                    Welcome Back !!
                                </h4>
                                <h6 className='text-center text-dark w-100'>Please Enter your Details</h6>

                        </div>

                        </div>

                    <form className='px-lg-1' onSubmit={onHandelsubmit}>
                    
                    <div className="mb-4 mt-3">
                    <label  className="admin_login_lable">Email</label>
                    <input type="email" required className="form-control admin_login_inputs" id="Username" onChange={onHandelchange} value={user_info.username} placeholder="" name="email"/>
                </div>
                <div className="mb-4">
                    <label  className="admin_login_lable">Password</label>
                    <div className="input-group">
                        <input type={showpassword?"text":"password"} required className="form-control admin_login_inputs" onChange={onHandelchange} value={user_info.password}  id="pwd" placeholder="" name="password"/>
                        <span className="input-group-text admin_password_icon_container p-0" onClick={toogle_password}><VisibilityIcon  className='password_icon'/></span>
                     </div>
                </div>
                <div className=' d-lg-flex justify-content-between p-1 '>
                <div className="form-check mb-3">
                    <label className="form-check-label admin_login_lable">
                    <input className="form-check-input" type="checkbox" name="remember"/> Remember me
                    </label>
                    </div>
                       <div className='mb-3'>
                       <span className='text-dark forgot_button' onClick={Modal_toggle}>Forgot password ?</span>
                       </div>
                   
                    
                    
                </div>
                <button type="submit" className="btn btn-success fw-bolder w-100">Submit</button>
                    </form>
                </div>
            </div>

        </div>
                </div>

            </div>
            <Forgotpassword modal_status={modal_status} Modal_toggle={Modal_toggle} />
            <Password_change_modal modal_status={pws_change} Modal_toggle={pws_toogle} account={account}/>
        </div>
        
    </div>
  )
}

export default Loginpage