import React ,{useEffect, useState} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {  useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Alert_Modals from './Alert_Modals';
import { compare_password } from '../Functions/admin_functions';
import { check_oldpassword } from '../Functions/api_functions';
import { checkPasswordStrength } from '../Functions/admin_functions';
import api from '../API/api';
function Password_change_modal({modal_status,Modal_toggle,account}) {
    const navigate= useNavigate();
    const [disable,setDisable]=useState(false);
    const [password_alert_dialog,setAlert]=useState(false);
    const [psw_text,setPsw_text]=useState("");
    const[alert_type,setType]=useState();
    const [user_info,setInfo]= useState({
        old_password:"",
        new_password:"",
        confirm_password:""
    })
    const alertModals= Alert_Modals();
    const[show,setShow]= useState({
        new_password:false,
        confirm_password:false,
        old_password:false,
    })
    const onHandelChange=(e)=>{
       
        if (e.target.name=="new_password")
            {
          const result = checkPasswordStrength(e.target.value);
                if( !result.status)
                {
                  setAlert(true);
                  setPsw_text(result.message);
                  setType("error");
                  setDisable(true);
                }
                else{
                  setAlert(true);
                  setPsw_text(result.message);
                  setType("success");
                  setDisable(false);
                  
                }
            }
        setInfo( (prev)=>({
            ...prev,
            [e.target.name]:e.target.value
          }))
        }

    // for handeling visiblity of password
    const show_old_password=()=>{
        setShow((pre)=>({
            ...pre,
            old_password:!pre.old_password
        }))
    }
    const show_new_password=()=>{
        setShow((pre)=>({
            ...pre,
            new_password:!pre.new_password
        }))
    }
    const show_confirm_password=()=>{
        setShow((pre)=>({
            ...pre,
            confirm_password:!pre.confirm_password
        }))
    }
    const onSubmit=(e)=>
    {
        e.preventDefault(); 
        if(compare_password(user_info.new_password,user_info.confirm_password))
        {  
            check_oldpassword(account.id,user_info.old_password).then(async(res)=>{
                if(res.status=="success")
                {
                    try{
                        const response=await api.post("/Admin/update/password",{
                            id:account.id,
                            password:user_info.new_password
                        })   
                        if(response.data.status=="success"){
                            alertModals.showAlert("Password changed Successfully","success").then(()=>{
                                setInfo({
                                    old_password:"",
                                     new_password:"",
                                     confirm_password:""
                                });
                                if(account.role=="admin"){
                                    navigate("/Admin/Manage-Admin");
                                }
                               else{
                                navigate("/Admin/Manage-appointments");
                               }
                            });
    
                        }
                        else{
                            alertModals.showAlert("failed to update password due to internal server error","error").then(()=>{});
                        }
                    }catch(error){
                        console.log(error);
                        alertModals.showAlert(error.message,"error").then(()=>{});
                        
                    }
                  
                    
                }
                else
                {
                alertModals.showAlert("Old password you enterd is incorrect","error").then(()=>{});
                
                }
                
            });
        }
        else
        {
            alertModals.showAlert("New password and confirm password do not Match","error").then(()=>{});
           
        }
       
    }
    
    useEffect(()=>{
        // if(member==null)
        // {
        //     navigate("/");
        // }
    },[])
    const toggle = () => Modal_toggle();
  return (
    <div className='container-fluid'>
    <Modal isOpen={modal_status} size="xl" toggle={toggle} >
<ModalHeader  className='modal_color text-center w-100 ' toggle={toggle}><p className='w-100 text-center'> Change Password</p></ModalHeader>
<ModalBody className='modal_color'>

<form onSubmit={onSubmit}>
<div className='container-fluid p-2'>
<div className='row'>
<div className='col-sm-12 col-md-4'>
  <img src='/Assets/forgot-password.png' className='img-fluid img-round'/>
</div>
<div className='col-sm-12 col-md-8 bg-white p-3 rounded'>



<div className="mb-3 mt-3">
<label for="email" className="form_label">Old Password:</label>
<div className="input-group">
  <span className="input-group-text fw-bold">*</span>
  <input type={show.old_password?"text":"password"} className="form-control modal_text" required value={user_info.old_password} onChange={onHandelChange} id="cashier_name" placeholder="" name="old_password"/>
<span className="input-group-text fw-bold" onClick={show_old_password}><VisibilityIcon/></span>
</div>
</div>


<div className="mb-3 mt-3">
<label for="email" className="form_label">New password:</label>
<div className="input-group">
  <span className="input-group-text fw-bold">*</span>
<input type={show.new_password?"text":"password"} className="form-control modal_text" required value={user_info.new_password}  onChange={onHandelChange} id="new_-password" placeholder="" name="new_password"/>
<span className="input-group-text fw-bold" onClick={show_new_password}><VisibilityIcon/></span>
</div>
{password_alert_dialog && <h6 className={alert_type=="success"?" p-1 text-success":"p-1 text-danger"}>{psw_text}</h6>}

</div>

<div className="mb-3 mt-3">
<label for="email" className="form_label">Confirm password:</label>
<div className="input-group">
  <span className="input-group-text fw-bold">*</span>
<input type={show.confirm_password?"text":"password"} className="form-control modal_text" required value={user_info.confirm_password}  onChange={onHandelChange} id="confirm_password" placeholder="" name="confirm_password"/>
<span className="input-group-text fw-bold" onClick={show_confirm_password}><VisibilityIcon/></span>
</div>
</div>

<div className='row p-3'>
<button type='submit' disabled={disable}  className='btn btn-primary' >
Confirm
</button>
</div>


</div>

</div>

</div> 

</form>
</ModalBody>
<ModalFooter className='modal_color'>

<Button color="secondary" onClick={toggle}>
Done
</Button>

</ModalFooter>

</Modal>

</div>
  )
}

export default Password_change_modal