import React ,{useState,useEffect} from 'react'
import TitleHeader from '../components/TitleHeader'
import { RefreshRounded } from '@mui/icons-material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import SimpleBackdrop from '../components/SimpleBackdrop';
import Alert_Modals from '../components/Alert_Modals';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { get_today_date } from '../Functions/admin_functions';
import Transaction_view from '../components/views/Transaction_view';
import { get_all_transaction } from '../Functions/api_functions';
import api from '../API/api';

function Transaction_page() {
const [account,setAccount]=  useState((JSON.parse(localStorage.getItem("Admin-Account")))||null);
const navigate=useNavigate();
const [allTransaction,setTransaction]=useState(null);
const alertModals= Alert_Modals();
const [filter_data,setfilter]=useState({
    from_date:"",
    to_Date:""
})
// function for fetching data
const fetch_data=()=>{
    get_all_transaction().then((res)=>{
            setTransaction(res.transactions)
    })
}
useEffect(()=>{
    if(account==null){
        navigate("/");
    }
    fetch_data();

},[])
// for handling input change
    const onHandelChange=(e)=>{
        setfilter( (prev)=>({
            ...prev,
            [e.target.name]:e.target.value
          }))
    }
 // function to referesh the data in the table
 const refresh_data=()=>{
  
        setTransaction(null);
        fetch_data();
    
}
// for handling form on search
const onHandelSubmit=async(e)=>{
    e.preventDefault();
    setTransaction(null);
    try {
        const response= await api.post("/Transaction/filterd",{filter_data})
        setTransaction(response.data.transactions)
    } catch (error) {
        console.log(error);
        alertModals.showAlert(error.message,"error").then(()=>{});
    }
}
return (
    <div className='container-fluid '>
        <div className='row'>
            <div className='col-sm-12  whole_title_container shadow'>
                <TitleHeader icon={<AutoGraphIcon/>} title="Transaction Management"/>
            </div>
            <div className='col-sm-12 admin_color mt-5 p-3 rounded'>
                    <div className='row'>
                    

                        <div className='col-sm-8 d-flex '>
                            <form  className="w-100 "onSubmit={onHandelSubmit}>
                            <div className="row">
   <div className="col-sm-12 col-lg-4">
   <div className="form-floating mb-3 mt-3">
        <input type='date' required  className='form-control'onChange={onHandelChange} max={moment().format('YYYY-MM-DD')} value={filter_data.from_date} name="from_date"/>
       <label htmlFor="Gender">From Date</label>
     </div>    
   </div>
   <div className="col-sm-12 col-lg-4">
   <div className="form-floating mb-3 mt-3">
        <input type='date' required  className='form-control'onChange={onHandelChange} max={moment().format('YYYY-MM-DD')} value={filter_data.to_Date} name="to_Date"/>
       <label htmlFor="Gender">To Date</label>
     </div>    
   </div>
   <div className="col-sm-12 col-lg-4 d-flex ">
    <button type="submit" className='btn btn-success mb-3 mt-3 w-100' >Search</button>
   </div>
 
</div>

                            </form>
                        </div>
                        <div className='col-sm-4  p-2 d-flex justify-content-end'>
                          
                            <button  className='btn btn-info mx-2 mt-3' style={{height:"50px"}} onClick={refresh_data}>Refresh <RefreshRounded/> </button>

                        </div>
                            <div className='col-sm-12 p-2'>

                             {  allTransaction? <Transaction_view refresh_data={refresh_data} result={allTransaction}/>:<SimpleBackdrop/>}
                            </div>
                    </div>
            </div>
        </div>
    </div>
  )
}

export default Transaction_page