import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import { Route,Routes } from 'react-router-dom';
import Admin_management from './pages/Admin_management';
import Loginpage from './pages/Loginpage';
import Edit_user_page from './pages/Edit_user_page';
import Appointment_management from './pages/Appointment_management';
import Treatment_management from './pages/Treatment_management';
import Transaction_page from './pages/Transaction_page';
import Dashbord from './pages/Dashbord';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Doctors from './pages/Doctors';

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
   <div className="container-fluid p-0">
      <Routes>
      <Route path="/" element={ <Loginpage/>}/>  
      <Route path="/Admin/Dashbord" element={<Navbar><Dashbord/></Navbar>}/>
      <Route path="/Admin/Manage-Admin" element={<Navbar><Admin_management/></Navbar>}/>  
      <Route path="/Admin/Edit-Admin/:email" element={<Navbar><Edit_user_page/></Navbar>}/>   
      <Route path="/Admin/Manage-appointments" element={<Navbar><Appointment_management/></Navbar>}/>
      <Route path="/Admin/Treatments" element={<Navbar><Treatment_management/></Navbar>}/>
      <Route path="/Admin/Payment reports" element={<Navbar><Transaction_page/></Navbar>}/>
      <Route path="/Admin/Manage-doctors" element={<Navbar><Doctors/></Navbar>}/>

      </Routes>
      
   </div>
   </LocalizationProvider>
  );
}

export default App;
