import React ,{useState} from 'react'
import MUIDataTable from 'mui-datatables'
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert_Modals from '../Alert_Modals';
import { Edit } from '@mui/icons-material';
import Swal from 'sweetalert2';
import api from '../../API/api';
import Edit_Treatment_modal from '../Edit_Treatment_modal';
import { set_selected_treatment } from '../../Store/Action';
import SimpleBackdrop from '../SimpleBackdrop';
function Treatment_view_table( {result,refresh_data}) {
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const [isloading,setloading]=useState(false);
    const [EditModal, setModal ] = useState(false);
    const alertModals= Alert_Modals();
;
    // for toggle edit modal
    const toogleModal=()=>
        {
          setModal(!EditModal);
         
        }

    // for currency handling
    const nf = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    // fucntion for deactivating  treatment
    const handel_Deactivation= async(id)=>{
        try {
            setloading(true);
            const response= await api.put("/Treatment/Deactivate/",
                {
                    id
                }
            )
            if(response.data.status="success" && response.data.result>=1)
                {
                    alertModals.showAlert(" Treatment Deactivated successfully", "success")
                    .then(() => {
                        refresh_data()
                      // Reset state or perform actions after alert is closed
                    });
                  
                }
            else{
                alertModals.showAlert("Error occured Please try again ", "error")
                .then(() => {
                    refresh_data()
                  // Reset state or perform actions after alert is closed
                });
               
                   
            }
        } catch (error) {
            console.log(error);
            alertModals.showAlert(error.message,"error").then(()=>{
      
            });
        }finally{
            setloading(false);
        }
    }
    const handel_delete=async(id,img)=>{
       
        Swal.fire({
            title: "Are you sure?",
            text: "Your about to remove a Treatment",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
          }).then(async(result) => {
            if (result.isConfirmed) {
             try {
                setloading(true);
                const response= await api.delete("/Treatment/delete",
                    {params:{
                        id,
                        img
                    }}
                )
                if(response.data.status=="success"){
                    alertModals.showAlert("Treatment Removed","success").then(()=>{
                        refresh_data();
                    });
                }
             } catch (error) { 
                console.log(error);
                alertModals.showAlert(error.message,"error").then(()=>{ })
             }finally{
                setloading(false);
             }
            }
          });
      
    }
    const handel_Edit=async(id)=>{
        const [treatment]=result.filter((data)=>data.id==id);
        dispatch(set_selected_treatment(treatment));
        toogleModal();
       

    }
    const options = {
        filterType: 'checkbox',
        pagination:true,
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,15,20],
        rowHover:true,
        selectableRowsHeader:false,
        selectableRows:'none'
      };

      const columns = [
       
        {label:"Image",name:"image", options:{filter:false,

            customBodyRender:(value)=>{
               
               return(
                <img src={`https://server.enqufacial.com/Treatments/${value}`} style={{maxHeight:"100px"}} className="img-fluid rounded mx-auto " alt="treatment-pic"/>
               )
            }
        }},
        {label:"Name",name:"name" , options:{filter:false,
            customBodyRender:(value)=>{
                return(
                    <h6 className='fw-bold'>{value}</h6>
                )
            }
        }},
        {label:"Duration",name:"duration", options:{filter:false,
            customBodyRender:(value)=>{
                return(
                    <h6 className='fw-bold'>{value} Min</h6>
                )
            }
        }},
        {label:"Price",name:"price",options: {
            filter: false,
            customBodyRender:(value,tableMeta)=>{
                return(
                   <h5> <span className="badge bg-success text-light p-2 rounded fw-bold">{nf.format(value)} </span></h5>
                )
            }
            
           }}
        ,
        {label:"Description",name:"description",options: {
            filter: false,
            customBodyRender:(value,tableMeta)=>{
                return(
                   <p> {value}</p>
                )
            }
            
           }}
        ,
        
        {label:"Status",name:"status",options:{
            customBodyRender:(value)=>{
                if(value=="active")
                { return (
                    <span className=' btn btn-success  p-1  text-white rounded fw-bold '>{value}</span>
                )}
                else if (value=="inactive")
                {
                    return (
                        <span className='btn btn-danger text-white p-1 rounded fw-bold'>{value}</span>
                    )
                }
            }
        }},
        {label:"created at",name:"created_at",
            options:{
                filter:false,
                customBodyRender:(value)=>(
                    moment(value ).format('YYYY-MM-DD')
                ) }
        },
        {label:"Action",name:"id", options:{filter:false,
                customBodyRender:(value ,tableMeta)=>(
                   
                       <>
                        <span className=' btn btn-success rounded mx-1 my-1'onClick ={()=>handel_Edit(value)}>Edit<Edit  className=' btn-success '/></span>
                        <span className='btn btn-warning mx-1 my-1'onClick ={()=>handel_Deactivation(value)}>Deactivate <LockIcon  className='btn-danger'/></span>
                        <span className='btn btn-danger mx-1 my-1'onClick ={()=>handel_delete(value,tableMeta.rowData[0])}>Remove <DeleteIcon  className='btn-danger'/></span>

                       </>
                   
                )
        }},

    ];
    if(  result!== undefined && result !== null )  { 
        return (
       <div className='container'>
        <div>
           {isloading && <SimpleBackdrop/> }
        </div>
               <MUIDataTable 
          title={"Treatments"}
          data={result}
          columns={columns}
          options={options}
          className="text-color w-100"
        />
        <Edit_Treatment_modal refresh_data={refresh_data} modal_status={EditModal} Modal_toggle={toogleModal} />
       </div>
        )}
        else
        {
          return (
         <></>
            )
          }
      }



export default Treatment_view_table