import axios from "axios";
import { logout } from "../Functions/api_functions";
const api= axios.create({
    // baseURL:'http://127.0.0.1:5004',
    baseURL:'https://server.enqufacial.com',
    withCredentials:true,
    
})

// for adding authorization header to each request with the access token
 api.interceptors.request.use(
    (config)=>{
        const accessToken=JSON.parse(localStorage.getItem("Access-Token"));
            if(accessToken){
                config.headers['Authorization']=`Bearer ${accessToken}`;
            }
            // console.log('Request headers:', config.headers);
            return config; 
        },
        (error)=>{
            return Promise.reject(error)
        }
 );


 // Response Interceptor
api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
        
      if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
  
        try {
          // Attempt to refresh the token
          const refreshResponse = await api.post('/Refresh/Token');
          const { accessToken } = refreshResponse.data;
  
          // Update localStorage with the new access token
          localStorage.setItem('Access-Token', JSON.stringify(accessToken));
  
          // Update the Authorization header with the new token
          api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
          originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;

  
          // Retry the original request with the new token
          return api(originalRequest);
        } catch (refreshError) {
          // If token refresh fails, log the user out or take another action
          console.error('Token refresh failed here 1:', refreshError);
          // Perform logout or redirect to login
          logout(true)
          return Promise.reject(refreshError);
        }
      }
     
    
    else if(error.response.status === 401)
    {
        console.error('Refresh token failed here 2:', error);
        setTimeout(() => {
          logout();
           }, 30000);
   
        Promise.reject(error);
    }
      return Promise.reject(error);
    }
  );

export default api;