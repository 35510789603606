import React,{useEffect, useState} from 'react'
import VaccinesIcon from '@mui/icons-material/Vaccines';
import Alert_Modals from '../components/Alert_Modals';
import TitleHeader from '../components/TitleHeader';
import { useNavigate } from 'react-router-dom';
import SimpleBackdrop from '../components/SimpleBackdrop';
import { RefreshRounded } from '@mui/icons-material';
import Add_treatment_modal from '../components/Add_treatment_modal';
import Treatment_view_table from '../components/views/Treatment_view_table';
import { get_all_treatments } from '../Functions/api_functions';

function Treatment_management() {
  const [account,setAccount]=  useState((JSON.parse(localStorage.getItem("Admin-Account")))||null);   
   const [Addtreatments, setModal ] = useState(false);
  const navigate=useNavigate();  
  const [allTreatments,setTreatments]=useState(null);
  
    // fucntion for fetching data
    const fetch_data=()=>{
      get_all_treatments().then((res)=>{
        if(res.status=="success"){
          setTreatments(res.treatments);
        }
      })

    }
     //toggling add appointment modal
     const toogleModal=()=>
        {
          setModal(!Addtreatments);
         
        }
          // function to referesh the data in the table
          const refresh_data=()=>{
       
            setTreatments(null);
            fetch_data();
                
        }
        useEffect(()=>{
          if(account==null){
            navigate("/");
        }
        fetch_data()
        },[])
  return (
    <div className='container-fluid'>
    <div className='row'>
        <div className='col-sm-12 whole_title_container shadwo'>
                <TitleHeader icon={<VaccinesIcon/>} title="Appointments"/>
        </div>
        <div className='col-sm-12 admin_color mt-5 p-3 rounded'>
                <div className='row'>

                    <div className='col-sm-6'>
                        
                    </div>
                    <div className='col-sm-6  p-2 d-flex justify-content-end'>
                        <button onClick={toogleModal} className='btn btn-primary mx-2 mt-3' style={{height:"50px"}}>Add New Treatment <VaccinesIcon/> </button>
                        <button  className='btn btn-info mx-2 mt-3' style={{height:"50px"}}onClick={refresh_data}>Refresh <RefreshRounded/> </button>

                    </div>
                        <div className='col-sm-12'>
                        {  allTreatments ? <Treatment_view_table refresh_data={refresh_data} result={allTreatments}/>:<SimpleBackdrop/>}
                        </div>
                </div>
        </div>

    </div>
    <Add_treatment_modal refresh={refresh_data} modal_status={Addtreatments} Modal_toggle={toogleModal} />
</div>
  )
}

export default Treatment_management