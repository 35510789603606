import React ,{useState} from 'react'
import MUIDataTable from 'mui-datatables'
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import Alert_Modals from '../Alert_Modals';
import api from '../../API/api';
import SimpleBackdrop from '../SimpleBackdrop';
import { DeleteOutline } from '@mui/icons-material';
import Swal from 'sweetalert2';
import Edit_doctor from '../Edit_doctor';
function Doctors_view({result,refresh_data}) {
    const alertModals= Alert_Modals();
    const options = {
        filterType: 'checkbox',
        pagination:true,
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,15,20],
        rowHover:true,
        selectableRowsHeader:false,
        selectableRows:'none'
      };
      const [doctor,setDoctor]=useState();
      const [modal_status,setModal]=useState(false);
      const Modal_toggle=()=>{
        setModal(!modal_status);
      }
      const handel_delete=(id)=>{
        Swal.fire({
            title: "Are you sure?",
            text: "Your about to remove a Doctor",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
          }).then(async(result) => {
            if (result.isConfirmed) {
             try {
                setloading(true);
                const response= await api.delete("/Doctor/delete",
                    {params:{
                        id,
                    }}
                )
                if(response.data.status=="success" && response.data.result>0){
                    alertModals.showAlert("Doctor Removed","success").then(()=>{
                        refresh_data();
                    });
                }
             } catch (error) { 
                console.log(error);
                if(error.response.data.error=="Cannot delete or update a parent row: a foreign key constraint fails (`enqufasv_enqu-skincare`.`appointments`, CONSTRAINT `appointments_ibfk_2` FOREIGN KEY (`doctor_id`) REFERENCES `doctors` (`id`) ON DELETE NO ACTION)"){
                    alertModals.showAlert("Can not Delete this Doctor they already have Appointment Booked","error").then(()=>{ })
                }
                else{
                    alertModals.showAlert(error.response.data.error,"error").then(()=>{ })

                }
                
             }finally{
                setloading(false);
             }
            }
          });
      }
      const handel_edit=(id)=>{
        const [selected]= result.filter((data)=>data.id==id);
        setDoctor(selected);
        Modal_toggle();
      }
      const [isloading,setloading]=useState(false);
      const columns = [
       
        {label:"Fullname",name:"name",options:{ 
            filter:false}},
        {label:"Gender",name:"gender",options:{ 
            filter:false}},
        {label:"Phonenumber",name:"phonenumber",options:{ 
            filter:false}},
        {label:"Email",name:"email" ,options:{ 
            filter:false}
        },
        

        {label:"created at",name:"created_at",
            options:{
                filter:false,
                customBodyRender:(value)=>(
                    moment(value ).format('YYYY-MM-DD')
                ) }
        },
        {label:"Action",name:"id", options:{
                customBodyRender:(value)=>(
                   
                       <>
                        <span className='btn btn-success fw-bold mx-1 my-1'onClick ={()=>handel_edit(value)}>Edit <EditIcon className='btn-success'/></span>
                        <span className='btn btn-danger fw-bold mx-1 my-1'onClick ={()=>handel_delete(value)}>Delete <DeleteOutline className='btn-danger'/></span>

                       </>
                   
                )
        }},

    ];

    if(  result!== undefined && result !== null )  { 
        return (
       <div className='container'>
       { isloading && <SimpleBackdrop/>}
       <Edit_doctor modal_status={modal_status} Modal_toggle={Modal_toggle}  refresh_data={refresh_data} doctor={doctor}/>
        <div>
        </div>
               <MUIDataTable 
          title={"Doctors report"}
          data={result}
          columns={columns}
          options={options}
          className="text-color"
        />
       </div>
        )}
        else
        {
          return (
          <Alert_Modals type="error" 
          text="Sorry no User found on the system !"
            />
            )
          }
}

export default Doctors_view