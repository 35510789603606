import * as ac_types from "./Actiontypes.js"

//  AMDIN ACTIONS////////////////////////////////////////////////////////////////////////
// SETTING USER DATA WHEN LOGIN IN FROM ADMIN
export const set_user_state=(user_data)=>
{
    return{
        type:ac_types.set_user,
        payload:user_data
    }
}
//FOR RESETING USER WHEN LOGIN OUT AS ADMIN
export const reset_user_state=()=>{
    return{
        type:ac_types.rest_user
    }
 }
// for setting selected user 
 export const set_selected_user=(user_data)=>{
    return{
        type:ac_types.selected_user,
        payload:user_data
    }
 }
 // for setting selecter treatment for edit
 export const set_selected_treatment=(treatment)=>{
    return{
        type:ac_types.selected_treatment,
        payload:treatment
    }
 }
 