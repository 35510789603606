import React,{useEffect, useState} from 'react';
import Alert_Modals from '../components/Alert_Modals';
import TitleHeader from '../components/TitleHeader';
import moment from 'moment';
import { RefreshRounded } from '@mui/icons-material';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import SimpleBackdrop from '../components/SimpleBackdrop';
import Add_appointment from '../components/Add_appointment';
import Appointment_View from '../components/views/Appointment_View';
import { get_all_appointments } from '../Functions/api_functions';
import api from '../API/api';
function Appointment_management() {
    const [AddAppointment, setModal ] = useState(false);
    const [allappointment,setAppointment]=useState(null);
    const alertModals= Alert_Modals();
    const [filter_data,setfilter]=useState({
      from_date:"",
      to_Date:"",
      status:""
  })
    const fetch_data= ()=>{
      get_all_appointments().then((res)=>{
        setAppointment(res.appointments);
      })
  }
  // for handling input change
  const onHandelChange=(e)=>{
    setfilter( (prev)=>({
        ...prev,
        [e.target.name]:e.target.value
      }))
}
     //toggling add appointment modal
     const toogleModal=()=>
        {
          setModal(!AddAppointment);
         
        }
          // function to referesh the data in the table
          const refresh_data=()=>{
           setfilter({
            from_date:"",
            to_Date:"",
            status:""
           })
            setAppointment(null);
            fetch_data()
                
        }
        //const for handling submit
        const onHandelSubmit=async(e)=>{
          e.preventDefault();
           try {
            setAppointment(null);

            const response= await api.post("/Appointment/get-filterd",{
              filter_data
            })
            setAppointment(response.data.appointments);
            console.log(response.data.appointments);
          } catch (error) {
            console.log(error);
        alertModals.showAlert(error.message,"error").then(()=>{}); 
        }
        }
        useEffect(()=>{
          fetch_data();

        },[])
       
  return (
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-sm-12 whole_title_container shadow'>
                    <TitleHeader icon={<MoreTimeIcon/>} title="Appointments"/>
            </div>
            <div className='col-sm-12 admin_color mt-5 p-3 rounded'>
                    <div className='row'>
                    

                        <div className='col-sm-8 p-2'>
                        <form  className="w-100 "onSubmit={onHandelSubmit}>
                            <div className="row">
                                          <div className="col-sm-12 col-lg-3">
                                          <div className="form-floating mb-3 mt-3">
                                                <input type='date' required  className='form-control'onChange={onHandelChange} value={filter_data.from_date} name="from_date"/>
                                              <label htmlFor="Gender">From Date</label>
                                            </div>    
                                          </div>
                                          <div className="col-sm-12 col-lg-3">
                                          <div className="form-floating mb-3 mt-3">
                                                <input type='date' required  className='form-control'onChange={onHandelChange}  value={filter_data.to_Date} name="to_Date"/>
                                              <label htmlFor="Gender">To Date</label>
                                            </div>    
                                          </div>
                                          <div className="col-sm-12 col-lg-3">
                                          <div className="form-floating mb-3 mt-3">
                                              <select className="form-control" onChange={onHandelChange}  value={filter_data.status} name='status'>
                                                        <option value="">---please select status---</option>
                                                        <option value="pending">Pending</option>
                                                        <option value="canceled">Canceled</option>
                                                        <option value="confirmed">Confirmed</option> 
                                                        <option value="completed">Completed</option>                                                   
                                                    </select>
                                                    <label htmlFor="title">Status</label>
                                              </div>    
                                          </div>
                                          <div className="col-sm-12 col-lg-3 d-flex ">
                                            <button type="submit" className='btn btn-success mb-3 mt-3 w-100' >Search</button>
                                          </div>
                                        
                                        </div>

                            </form>
                        
                        </div>
                        <div className='col-sm-4  p-2 d-flex justify-content-end'>
                            <button onClick={toogleModal}  className='btn btn-primary mx-2 mt-3' style={{height:"50px"}}>
                              Add Appointment <MoreTimeIcon
                            /> </button>
                            <button className='btn btn-info mx-2 mt-3'  onClick={refresh_data} style={{height:"50px"}}>Refresh <RefreshRounded/> </button>

                        </div>
                            <div className='col-sm-12'>
                            {  allappointment? <Appointment_View refresh_data={refresh_data} result={allappointment}/>:<SimpleBackdrop/>}

                            </div>
                    </div>
            </div>

        </div>
        <Add_appointment refresh_data={refresh_data} modal_status={AddAppointment} Modal_toggle={toogleModal} />
    </div>
  )
}

export default Appointment_management;