import React ,{useEffect, useState} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {  useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Alert_Modals from './Alert_Modals';
import api from '../API/api';
import { toggleButtonGroupClasses } from '@mui/material';


function Forgotpassword({modal_status,Modal_toggle}) {
    const [user_info,setInfo]= useState({
        email:"", 
    })
    const alertModals= Alert_Modals();
    // for handling change
    const onHandelChange=(e)=>{
       
        setInfo( (prev)=>({
            ...prev,
            [e.target.name]:e.target.value
          }))
        }
        const toggle = () => Modal_toggle();
 //for handling submit
 const onSubmit=async(e)=>
 {
     e.preventDefault(); 
    //  console.log(user_info);
     try {
         
        const response= await api.post("/Admin/Reset-password",
        {
            user_info
        })
        if(response.data.status=="success")
        {
        
        alertModals.showAlert(response.data.msg,"success").then(()=>{
          setInfo({
            email:""
          })
        });
        
        }
        else
        {
            alertModals.showAlert(response.data.msg,"error").then(()=>{});
        }
     } catch (error) {
         console.log(error);
        alertModals.showAlert(error.message,"error").then(()=>{});
        }
     
  }
  return (
    <div className='container-fluid'>
    <Modal isOpen={modal_status} size="xl" toggle={toggle} >
<ModalHeader  className='modal_color text-center w-100 ' toggle={toggle}><p className='w-100 text-center'> Rest Password</p></ModalHeader>
<ModalBody className='modal_color'>

<form onSubmit={onSubmit}>
<div className='container-fluid p-2'>
<div className='row'>
<div className='col-sm-12 col-md-4'>
  <img src='/Assets/forgot-password.png' className='img-fluid img-round'/>
</div>
<div className='col-sm-12 col-md-8 bg-white p-3 rounded'>

<div className="mb-3 mt-3">
    <p className='form_label'>Please enter your Email and a short email will be sent to you to reset the password !</p>
</div>
<div className="mb-3 mt-3">
<label for="email" className="form_label">Email:</label>
<div className="input-group">

  <input type="username" className="form-control modal_text" required value={user_info.email} onChange={onHandelChange} id="Email" placeholder="" name="email"/>

</div>
</div>




<div className='row p-3'>
<button type='submit'  className='btn btn-primary' >
Confirm
</button>
</div>


</div>

</div>

</div> 

</form>
</ModalBody>
<ModalFooter className='modal_color'>

<Button color="secondary" onClick={toggle}>
Done
</Button>

</ModalFooter>

</Modal>

</div>
  )
}

export default Forgotpassword