import React, { useEffect, useState } from 'react'
import AssessmentIcon from '@mui/icons-material/Assessment';
import TitleHeader from '../components/TitleHeader';
import Alert_Modals from '../components/Alert_Modals';
import api from '../API/api';
import PaidIcon from '@mui/icons-material/Paid';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import LanguageIcon from '@mui/icons-material/Language';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CheckIcon from '@mui/icons-material/Check';
import Admin_detail_cards from '../components/Admin_detail_cards';
import { Line,Bar } from 'react-chartjs-2';
import { CircularProgress } from '@mui/material';
import { Chart, registerables ,defaults} from 'chart.js'; // Import Chart and registerables
defaults.maintainAspectRatio=true;
defaults.responsive=true;

function Dashbord() {
  Chart.register(...registerables); // Register all components including scales
  const [formData,setData]= useState({
      range:"",
    
  });
  const [Loadingdata, setLoadingdata] = useState(true);
  const nf = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ETB',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  const alertModals=Alert_Modals();
  const [report,setReport]= useState(null);
  const [graphData,setGraph]=useState(null);
  const [barData,setBar]=useState(null);
  const onHandelChange=async(e)=>{
   if(e.target.value==""){

   }else
   {
    setLoadingdata(true);
    setData({
      range:e.target.value
    })
    try {
      const response= await api.get(`/Admin/report/${e.target.value}`);
    
      console.log(response.data);
      if(e.target.value=="today")
     { setReport({
        totalSales:response.data.totalSales.total_sales,
        totalWalkins:response.data.totalWalkins.Walkin,
        totalOnline:response.data.totalOnline.Online,
        totalAppointmentsCompleted:response.data.totalAppointments.completed_count,
        totalAppointemtnsConfirmed:response.data.totalAppointments.confirmed_count
      });
      setLoadingdata(false);
       setGraph([]);
       setBar(
        response.data.topTreatments
      )
    }
    else{
      setReport({
        totalSales:response.data.totalSales.total_sales,
        totalWalkins:response.data.totalWalkins.Walkin,
        totalOnline:response.data.totalOnline.Online,
        totalAppointmentsCompleted:response.data.totalAppointments.completed_count,
        totalAppointemtnsConfirmed:response.data.totalAppointments.confirmed_count
      });
      setGraph(
        response.data.graphreport
      );
      setBar(
        response.data.topTreatments
      )
      setLoadingdata(false);

    }
    } catch (error) {
      console.log(error);
      alertModals.showAlert(error.message,'error').then(()=>{

      })
    }
   }
   

  }
  
  useEffect(()=>{
    setReport({
      totalSales:0.0,
      totalWalkins:0,
      totalOnline:0,
      totalAppointmentsCompleted:0,
      totalAppointemtnsConfirmed:0
    })
    setLoadingdata(false);

  },[])

  return (
    <div className='container-fluid '>
           <div className='row  '>
            <div className='col-sm-12 whole_title_container shadow'>
                    <TitleHeader icon={<AssessmentIcon/>} title="Dashboard"/>
            </div>
            <div className='col-sm-12 admin_color mt-3 p-3 rounded '>
                <div className='col-sm-12 col-md-2'>
                <div className="form-floating mb-3 mt-3">
                 <select className="form-select" style={{maxHeight:"20px !important"}}  onChange={onHandelChange}  value={formData.range }name='range'>
                        <option value="">Select Range</option>
                        <option value="today">Today</option>
                        <option value="week">This Week </option>
                        <option value="month">This Month</option>
                 </select>
         <label htmlFor="Gender">Range</label>
            </div> 

                </div>
                {Loadingdata?
                <div className='d-flex w-100 justify-content-center'>
                  <CircularProgress className=''/>
                  </div>:
                  <div className='row'>
                <div className='col-sm-12 col-md mx-auto mt-3'>
                    <Admin_detail_cards title="Total Sales" Icon={<PaidIcon className='text-success' fontSize='large'/>} Data={nf.format(report?.totalSales)} time={formData.range}/>
                  </div>
                  <div className='col-sm-12 col-md mx-auto mt-3'>
                  <Admin_detail_cards title="Walk In Appointments" Icon={<DirectionsWalkIcon className='text-secondary' fontSize='large'/>} Data={report?.totalWalkins} time={formData.range}/>
                    </div>
                    <div className='col-sm-12 col-md mx-auto mt-3 '>
                    <Admin_detail_cards title="Online Appointments" Icon={<LanguageIcon className='text-info' fontSize='large'/>} Data={report?.totalOnline} time={formData.range}/>
                    </div>
                    <div className='col-sm-12 col-md mx-auto mt-3'>
                    <Admin_detail_cards title="Confirmed Appointments" Icon={<CheckIcon  className='text-warning' fontSize='large' />} Data={report?.totalAppointemtnsConfirmed} time={formData.range}/>
                    </div>
                    <div className='col-sm-12 col-md mx-auto mt-3'>
                    <Admin_detail_cards title="Completed Appointments" Icon={<TaskAltIcon  className='text-primary' fontSize='large'/>} Data={report?.totalAppointmentsCompleted} time={formData.range}/>
                    </div>

                </div>}
                <div className='row'>
                  <div className='col-sm-12 col-md-4 mx-auto p-3'>
                    <div  className='rounded p-2 shadow bg-white mt-3 card_container 'style={{minHeight:"200px"}}>
                   { Loadingdata?
                <div className='d-flex w-100 justify-content-center'>
                  <CircularProgress className=''/>
                  </div>:( graphData && <Line data={{
                      labels:formData.range=='week'? graphData&&graphData.map(data=>data.day):graphData.map(data=>data.month_name),
                    datasets:[{
                      label:"Revenue",
                      data:graphData.map(data=>data.total_sales),
                      borderColor: 'rgb(63,135,85)',
                    backgroundColor: 'rgb(63,135,85)',
                    tension: 0.1
                    }]
                    }}
                    options={{
                      plugins:{
                        title:{
                          text:"Revenue",
                          display:true,
                          align:"start",
                          font:{weight:"bold",size:"20px"},
                          color:"Brgb(31, 78, 85)"
                        }
                      }
                    }}/>)}
                      <h6 className='w-100 text-end mt-3 text-secondary'>{formData.range}</h6>
                    </div>
               
                  </div>
                  <div className='col-sm-12 col-md-4 mx-auto p-3'>
                  <div  className='rounded p-2 shadow bg-white mt-3 card_container'style={{minHeight:"200px"}}>
                  {
                  Loadingdata?
                <div className='d-flex w-100 justify-content-center'>
                  <CircularProgress className=''/>
                  </div>
                    :( barData && <Bar data={{
                      labels:barData && barData.map(data=>data.treatment_name),
                    datasets:[{
                      label:"User count",
                      data:barData.map(data=>data.total_treatments),
                      borderColor: 'rgb(63,135,85)',
                    backgroundColor: ["#519DE9","#4CB140","#5752D1","#F0AB00" ,"#6A6E73"],
                    borderRadius:5,
                    tension: 0.1
                    }]
                    }}
                    options={{
                      plugins:{
                        title:{
                          text:"Top-Treatment count",
                          display:true,
                          align:"start",
                          font:{weight:"bold",size:"20px"},
                          color:"Brgb(31, 78, 85)"
                        }
                      }
                    }}
                    />)}
                    <h6 className='w-100 text-end mt-3 text-secondary'>{formData.range}</h6>
                    </div>
                  </div>

                </div>

            </div>
           
           

        </div>

    </div>
  )
}

export default Dashbord