import React,{useState,useEffect} from 'react'
import { FaUserDoctor } from "react-icons/fa6";
import TitleHeader from '../components/TitleHeader'
import { RefreshRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'
import SimpleBackdrop from '../components/SimpleBackdrop';
import Alert_Modals from '../components/Alert_Modals';
import Add_doctors_modal from '../components/Add_doctors_modal';
import { get_all_adminside_accounts, get_all_doctors } from '../Functions/api_functions';
import Doctors_view from '../components/views/Doctors_view';

function Doctors() {
    const [account,setAccount]=  useState((JSON.parse(localStorage.getItem("Admin-Account")))||null);
    const navigate=useNavigate();
    const [AddUserModal, setModal ] = useState(false);
    const [allDoctors,setDoctors]=useState(null);
    const alertModals= Alert_Modals();
    const fetch_Data=()=>{
        get_all_doctors().then((res)=>{
            if(res.status=="success"){
                setDoctors(res.doctors);
            }
            else{
                alertModals.showAlert(res.error,"error").then(()=>{

                })
            }
        })
    }
        // function to referesh the data in the table
        const refresh_data=()=>{
          
                setDoctors(null);
                fetch_Data();
        }
    const toogleModal=()=>
        {
          setModal(!AddUserModal);
         
        }
        useEffect(()=>{
            if(account==null){
                navigate("/");
            }
            else{
                fetch_Data();
            }
           

        },[])
  return (
    <div className='container-fluid '>
    <div className='row'>
        <div className='col-sm-12  whole_title_container shadow'>
            <TitleHeader icon={<FaUserDoctor/>} title="Doctor Management"/>
        </div>
        <div className='col-sm-12 admin_color mt-5 p-3 rounded'>
                <div className='row'>

                    <div className='col-sm-6'>
                        
                    </div>
                    <div className='col-sm-6  p-2 d-flex justify-content-end'>
                        <button onClick={toogleModal} className='btn btn-primary mx-2' style={{maxHeight:"70px"}}> Add Doctor <FaUserDoctor/> </button>
                        <button className='btn btn-info mx-2'onClick={refresh_data }style={{maxHeight:"70px"}}>Refresh <RefreshRounded/> </button>

                    </div>
                        <div className='col-sm-12'>

                         {  allDoctors? <Doctors_view refresh_data={refresh_data} result={allDoctors}/>:<SimpleBackdrop/>}
                        </div>
                </div>
        </div>
    </div>
    <Add_doctors_modal modal_status={AddUserModal} Modal_toggle={toogleModal} />
</div>
  )
}

export default Doctors