import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import GroupIcon from '@mui/icons-material/Group';
import VaccinesIcon from '@mui/icons-material/Vaccines';

const Admin_NavItem=[
    {
    path:"/Admin/Dashbord",
    name:"Dashbord",
    icon:<AssessmentIcon />,
    role:"Admin"
    },
    {
        path:"/Admin/Manage-Admin",
        name:"Admin Users",
        icon:<PersonAddAltIcon />,
        role:"Admin"
        },
        {
            path:"/Admin/Manage-appointments",
            name:" Appointments",
            icon:<MoreTimeIcon/>,
            role:"Both"
            },
            {
                path:"/Admin/Treatments",
                name:"Treatments",
                icon:<VaccinesIcon/>,
                role:"Admin"
                },
                {
                    path:"/Admin/Manage-doctors",
                    name:"Doctors",
                    icon:<GroupIcon />,
                    role:"Admin"
                    },
                            {
                                path:"/Admin/Payment reports",
                                name:"Payment reports",
                                icon:<AutoGraphIcon />,
                                role:"Admin"
                                },
                              
                    
    ];
    export default Admin_NavItem ;