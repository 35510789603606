import React,{useEffect, useRef, useState} from 'react'
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import Alert_Modals from './Alert_Modals';
import MUIDataTable from 'mui-datatables';
import EditNoteIcon from '@mui/icons-material/EditNote';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Editor } from 'primereact/editor';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import api from '../API/api';
import { DeleteOutline, ResetTvOutlined } from '@mui/icons-material';
import SimpleBackdrop from './SimpleBackdrop';
function Addnotes({modal_status,Modal_toggle,appointment}) {
    const[notesData,setNotesdata]=useState({
        appointment_date:appointment.appointment_date,
        user_id:appointment.id,
        appointment_id:appointment.appointment_id,
        notes:null,
        note_id:null
    });
    const [isreadonly,setreadOnly]=useState(false);
    const[allnotes,setAllnotes]=useState();
    const [isloading,setloading]=useState(false);
    const [selectedNote,setSelectedNote]=useState(null);
    const alertModals= Alert_Modals();
    const toggle=()=>Modal_toggle();
    const handelTextChange=(e)=>{
        setNotesdata((prev)=>({
            ...prev,
            notes:e.htmlValue
        }))
    }
    // option to goback to add new note 
    const handelNewnote=()=>{
        setSelectedNote(null);
        setNotesdata((prev)=>({
            ...prev,
            notes:null
        }))
        setreadOnly(false);
        
        
    }
// load notes to view
    const hadelView=(id)=>{
        const [note]=allnotes.filter((data)=>data.note_id==id);
        setSelectedNote(note);
        alertModals.showAlert("Note loaded","success").then(()=>
            {
                setreadOnly(true);
                setNotesdata((prev)=>({
                    ...prev,
                    notes:note.note,
                    note_id:note.note_id
                }))
           
   
               
            });   
    }
    const handelEditnote=async(id)=>{
        console.log(notesData);
        try {
            setloading(true);
            const response=await api.put("/Notes/update",{
                notesData
            }) 
            if(response.data.status=="success"&& response.data.result>0){
                alertModals.showAlert("Note updated","success").then(()=>
                    {
                        fetch_data();
                        setreadOnly(true);
                     });
            }
           
        } catch (error) {
            console.log(error);
            alertModals.showAlert(error.message,"error").then(()=>{ });
        }finally{
            setloading(false);
        }
    }
    useEffect(()=>{},[isreadonly])
        // adding new note
        const handel_Addnote=async(note_id)=>{
            console.log(notesData);
            try {
                setloading(true);
                const response=await api.post("/Notes/add",{
                    notesData
                })
                alertModals.showAlert("New note added","success").then(()=>
                    {
                        fetch_data();
                        setNotesdata((prev)=>({
                            ...prev,
                            notes:null
                        }));
                        setreadOnly(false);
                     });
            } catch (error) {
                console.log(error);
                alertModals.showAlert(error.message,"error").then(()=>{ });
            }finally{
                setloading(false);
            }
    
        }
        const handel_RemoveNote=async(id)=>{
            Swal.fire({
                title: "Are you sure?",
                text: "Your about to remove a note from a session",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
              }).then(async(result) => {
                if(result.isConfirmed){
                  try {
                    setloading(true);
                    const response= await api.delete("/Notes/delete",
                      {
                        params:{
                          id
                        }
                      });
                      if(response.data.status=="success"){
                        alertModals.showAlert("Note Removed","success").then(()=>{
                          fetch_data();
                      });
                      }
                  } catch (error) {
                    console.log(error);
                    alertModals.showAlert(error.message,"error").then(()=>{});
                  }finally{
                    setloading(false);
                }
                }
              })
        }
    const options = {
        filterType: 'checkbox',
        pagination:true,
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,15,20],
        rowHover:true,
        selectableRowsHeader:false,
        selectableRows:'none',
       
      };
      const columns = [
        {label:"ID",name:"note_id" , options:{filter:false,
            customBodyRender:(value)=>{
                return(
                    <h6 className='p-0'>{value}</h6>
                )
            }
        }},
       
        {label:"Name",name:"name" , options:{filter:false,
            customBodyRender:(value)=>{
                return(
                    <h6 className='p-0'>{value}</h6>
                )
            }
        }},
    
       
        {label:"Appointment Time",name:"appointment_date",options: {
            filter: false,
            customBodyRender:(value,tableMeta)=>{
                return(
                   <h5> <span className="badge bg-success text-light p-2 rounded fw-bold">{moment(value, "YYYY-MM-DD hh:mm A").format('MMMM Do YYYY, h:mm A')} </span></h5>
                )
            }
            
           }}
       
        ,
      {label:"created at",name:"created_at",
            options:{filter:false,
                customBodyRender:(value)=>(
                    moment(value ).format('YYYY-MM-DD')
                ) }
        },
        {label:"Action",name:"note_id", options:{filter:false,
                customBodyRender:(value,tableMeta)=>{
                   
                     return(  <>
                        <span className='btn btn-primary mx-1 my-1'onClick ={()=>hadelView(value)}>View<ViewTimelineIcon className=' btn-primary'/></span >
                        <span className='btn btn-danger mx-1 my-1'onClick ={()=>handel_RemoveNote(value)}>Delete<DeleteOutline className=' btn-danger'/></span >

                       </>
                   
                )
            }
        }},

    ];
    //for fetching all the notes
    const fetch_data=async()=>{
        try {
            const response=await api.get("/Notes/get-all/indivdual",{params:{
                user_id:appointment.id
            }})
            setAllnotes(response.data.notes);
        } catch (error) {
            console.log(error);
            alertModals.showAlert(error.message,"error").then(()=>{ });
        }
    } 

    useEffect(()=>{
        fetch_data();
    },[])
  return (
    <div className="container-fluid">
    {isloading && <SimpleBackdrop/>}
    <div className='row'>
    <div className='col-sm-12'>
    <Modal  className='admin_color rounded' isOpen={modal_status} size="xl" toggle={toggle}>
        <ModalHeader className='admin_color text-center w-100 '  toggle={toggle}>
        <h2 className='w-100 text-center '> Add Notes <EditNoteIcon/></h2>
        </ModalHeader>
        <ModalBody className='admin_color'>
            {
                selectedNote? ( <div className='d-flex justify-content-around'>
                    <div>
                    <button className='btn btn-primary w-100 fw-bold' onClick={()=>setreadOnly(false)}>Edit <EditNoteIcon/></button>

                    </div>
                    <div>
                    <button className='btn btn-info w-100 fw-bold' onClick={handelNewnote} >Add new Note <EditNoteIcon/></button>

                    </div>
                </div>):<></>
            }
                   
                <div className='row'>
                    <div className='col-sm-12 col-md-8 mx-auto p-3'>
                        {
                            isreadonly?(
                                // <Editor value={notesData.notes} readOnly onTextChange={handelTextChange} style={{ height: '320px',borderRadius:"5px",backgroundColor:"white" }}/>
                                <div dangerouslySetInnerHTML={{ __html: notesData.notes }} className='bg-white rounded p-3'  style={{height:"320px",overflow:"scroll"}}   >
                                    </div>

                            ):(
                                <Editor value={notesData.notes}  onTextChange={handelTextChange} style={{ height: '320px',borderRadius:"5px",backgroundColor:"white" }}/>

                            )
                        }
                    </div>

                </div> 
                <div className='row' mt-3>
                <div className='col-sm-12 col-md-8 mx-auto p-3 '>
                    {
                        !selectedNote? (
                            <button className='btn btn-success w-100 fw-bold' onClick={handel_Addnote}>Add note <EditNoteIcon/></button>
                        ):(
                            <button className='btn btn-primary w-100 fw-bold' onClick={()=>handelEditnote(notesData.note_id)}>Update note <EditNoteIcon/></button>
                        )
                    }
                   
                </div> 
                </div> 
                <div className='container-fluid mt-3'>
                <MUIDataTable 
          title={"Previous Notes"}
          data={allnotes}
          columns={columns}
          options={options}
          className="text-color"
        />
                </div>

                </ModalBody>
                <ModalFooter className='admin_color'>

<Button color="secondary" onClick={toggle}>
Done
</Button>

</ModalFooter>
            </Modal>
    </div>
    </div>
    </div>
  )
}

export default Addnotes