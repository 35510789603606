import React ,{ useState,useEffect }from 'react';
import TitleHeader from '../components/TitleHeader';
import { checkPasswordStrength } from '../Functions/admin_functions';
import api from '../API/api';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Alert_Modals from '../components/Alert_Modals';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { useSelector } from 'react-redux';
import SimpleBackdrop from '../components/SimpleBackdrop';
function Edit_user_page() {
    const [account,setAccount]=  useState((JSON.parse(localStorage.getItem("Admin-Account")))||null);
    const [selectedAccount,setSelected]=useState(useSelector((state)=>state.admin_reducer.selected_user)||null);
    const [disable,setDisable]=useState(false);
    const [password_alert_dialog,setAlert]=useState(false);
    const [psw_text,setPsw_text]=useState("");
    const [psw_type,setPsw_type]=useState("");
    const alertModals= Alert_Modals();
    const [isloading,setloading]=useState(false);
    const navigate=useNavigate();
    const [pwsvisisble,SetVisisble]=useState(false);
    const [user_info,setInfo]=useState({
        name:"",
        gender:"",
        email:"",
        phonenumber:"",
        role:"",
        password:"",
        status:"",
  
    })
    useEffect(() => {
        if(account==null && selectedAccount==null)
        {
        //   navigate("/");
        }
        else{
           
        //   setInfo(
        //    { name:selectedAccount.name,
        //     gender:selectedAccount.gender,
        //     email:selectedAccount.email,
        //     phonenumber:selectedAccount.phonenumber,
        //     role:selectedAccount.role,
        //     password:selectedAccount.password,
        //     status:selectedAccount.status,}
        //   )
        setInfo(selectedAccount)
        }
      }, [selectedAccount])
       //for handiling password visiblity
  const togglePassword=()=>{
    SetVisisble(!pwsvisisble);
  }
  //for handling input change
  const onHandelChange=(e)=>{
  if (e.target.name=="password")
      {
    const result = checkPasswordStrength(e.target.value);
          if( !result.status)
          {
            setAlert(true);
            setPsw_text(result.message);
            setPsw_type("error");
            setDisable(true);
          }
          else{
            setAlert(true);
            setPsw_text(result.message);
            setPsw_type("success");
            setDisable(false);
            
          }
  
  
      }  
        setInfo( (prev)=>({
            ...prev,
            [e.target.name]:e.target.value
          }))  
    }
    //for handling form submition
     //for handling the form
     const onHandelSubmit=async(e)=>
        {
            e.preventDefault()
            console.log(user_info);
            try {
              setloading(true);
                const response= await api.put("/Admin/Update/account",
                    {
                        user_info
                    }
                );
               
                if(response.data.status=="success" && response.data.result==1){
                    alertModals.showAlert(" Account Updated successfuly", "success")
                    .then(() => {
                      navigate("/Admin/Manage-Admin");
                      // Reset state or perform actions after alert is closed
                    });
                }
            } catch (error) {
              console.log(error);
              alertModals.showAlert(error.message,"error").then(()=>{});
            }finally{
              setloading(false);
            }
        }
  return (
    <div className='container-fluid'>
      {isloading && <SimpleBackdrop/>}
        <div className='row'>
            <div className='col-sm-12 whole_title_container shadow'>
                <TitleHeader icon={<ModeEditOutlineIcon/>} title="Edit User Account"/>
            </div>

            <div className='row'>
                <div className='col-sm-12  col-lg-8 mx-auto  admin_color mt-5 p-3 rounded'>
                <form onSubmit={onHandelSubmit}>      

                <div className="row">
    <div className="col-sm-12  ">
            <div className="form-floating mb-3 mt-3">
        <input type="text" className="form-control" onChange={onHandelChange} required value={user_info?.name}id="firstname" placeholder="Enter first name" name="name"/>
        <label htmlFor="firstname">Full Name</label>
        </div>    
    </div>
 
  </div>

  <div className="row">
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mt-3 mb-3">
      <input type="email" className="form-control" disabled={true} value={user_info?.email} onChange={onHandelChange} required id="email" placeholder="Enter email" name="email"/>
      <label htmlFor="email">Email</label>
    </div>
        </div>
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mt-3 mb-3">
          <input type="text" className="form-control"  value={user_info?.phonenumber} onChange={onHandelChange} required id="phonenumber" placeholder="Enter phonenumber" name="phonenumber"/>
      <label htmlFor="phonenumber">Phonenumber</label>
    </div>
    </div>
</div>

    <div className='row'>
      <div className='col-sm-12'>
      <div className="input-group form-floating mt-3 mb-3 ">
          <input type={pwsvisisble?"text":"password"} className="form-control" value={user_info?.password} onChange={onHandelChange} required id="pwd" placeholder="Enter password" name="password"/>
          <label htmlFor="pwd">Password</label>
          <span className="input-group-text" onClick={togglePassword}><VisibilityIcon/></span>
        </div>
        {password_alert_dialog && <h6 className={psw_type=="success"?" p-1 text-success":"p-1 text-danger"}>{psw_text}</h6>}

      </div>
    </div>
 
    <div className="row">
      <div className="col-sm-12 col-lg-6">
      <div className="form-floating mb-3 mt-3">
          <select className="form-control" onChange={onHandelChange} required value={user_info?.gender} name='gender'>
             <option value="">please select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
          </select>
          <label htmlFor="Gender">Gender</label>
        </div>    
      </div>
    <div className="col-sm-12 col-lg-6">
      <div className="form-floating mb-3 mt-3">
      <select className="form-control" onChange={onHandelChange} required value={user_info?.role} name='role'>
                <option value="">---please select role---</option>
                <option value="admin">Admin</option>
                <option value="reception">Reception</option>
             
            </select>
            <label htmlFor="title">Role</label>
      </div>
    </div>
  </div> 
  <div className="row">
<div className='col-sm-12'>
<div className="form-floating mb-3 mt-3">
      <select className="form-control" onChange={onHandelChange} required value={user_info?.status} clas name='status'>
                <option value="">---please select status---</option>
                <option value="active" className='bg-success text-white'>Active</option>
                <option value="inactive"className='bg-danger text-white'>Inactive</option>
            </select>
            <label htmlFor="title">Status</label>
      </div>
</div>
 
  </div>
   

       <div className='row p-3'>
           <button type='submit' disabled={disable}  className='btn btn-success fw-bold' >
                       Submit
            </button>
        </div>
                </form>
                </div>
            </div>
        </div>
        </div>
  )
}

export default Edit_user_page