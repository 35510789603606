import React ,{useEffect, useState} from 'react'
import DetailsIcon from '@mui/icons-material/Details';
import Alert_Modals from './Alert_Modals';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import api from '../API/api';

import { get_all_doctors, get_appointment_treatments } from '../Functions/api_functions';
import { Add, Close } from '@mui/icons-material';
import Swal from 'sweetalert2';
import AddExtra_modal from './AddExtra_modal';
import Addnotes from './Addnotes';
import SimpleBackdrop from './SimpleBackdrop';
import moment from 'moment';
function Appointment_Detailpage({modal_status,Modal_toggle,appointments,refresh_data,handel_action}) {
  const toggle = () => Modal_toggle();
  const [extraModal,setExtrmodal]=useState(false);
  const[notesModal,setNotesModal]=useState(false);
  const [account,setAccount]=  useState((JSON.parse(localStorage.getItem("Admin-Account")))||null);   

  const toggle_extra_modal=()=>{
    setExtrmodal(!extraModal);
  }
  const toogle_notes_modal=()=>{
    setNotesModal(!notesModal);
  }
  const alertModals= Alert_Modals();
  const appointment=appointments;
  let price=0.00;
  let duration=0;
  const[treatments,setTreatments]=useState(null)
  const[doctors,setDoctors]=useState(null);
  const [Paymentmethod,setMethod]=useState();
  const [selectedDoctor,setDoctor]=useState(appointment?.doctor_name);
  const[isloading,setloading]=useState(false);
   // for currency handling
   const nf = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ETB',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  const handelSubmit=async(e)=>{
    e.preventDefault ();
    const now=moment();
    if (moment(appointment?.appointment_date).isAfter(now)){
      alertModals.showAlert("Appointment can not be Completed because the appointment has not passed yet", "warning")
      .then(() => {
        
      });

    }
    else{
      try {
    
        setloading(true);
        const response= await api.put("/Appointment/Update-status",
            {
              id:appointment.appointment_id,
              status :'completed',
              amount:price,
              Paymentmethod,
              selectedDoctor
            }
        );
       
        if(response.data.status=="success")
            {
              alertModals.showAlert("Appointment Completed", "success")
            .then(() => {
                refresh_data()
              // Reset state or perform actions after alert is closed
            });
  
            }
            
        
       } catch (error) {
        console.log(error);
        alertModals.showAlert(error.message,"error").then(()=>{});
       }finally{
        setloading(false)
       }

    }
    

  }
  const handel_remove_treatment=async(treatment_id)=>{
    Swal.fire({
      title: "Are you sure?",
      text: "Your about to remove a Treatment",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async(result) => {
      if(result.isConfirmed){
        try {
          setloading(true)
          const response= await api.delete("/Appointment/appointment-treatment/delete",
            {
              params:{
                appointment_id:appointment.appointment_id,
                treatment_id,
              }
            });
            if(response.data.status=="success"){
              alertModals.showAlert("Treatment Removed","success").then(()=>{
                fetch_data();
            });
            }
        } catch (error) {
          console.log(error);
          alertModals.showAlert(error.message,"error").then(()=>{});
        }finally{
          setloading(false)
         }
      }
    })
  
  }
  const fetch_data=()=>{
    get_appointment_treatments(appointment?.appointment_id).then((res)=>{
      setTreatments(res.appointments_treatments);
     
    })
    get_all_doctors().then((res)=>{
      setDoctors(res.doctors)
    })
  }
  useEffect(()=>{
    fetch_data();
  },[appointment,])
  const status_display=(value)=>{
    if(value=="pending")
      { return (
          <span className=' btn btn-info  p-1  text-white rounded fw-bold '>{value}</span>
      )}
      else if (value=="canceled")
      {
          return (
              <span className='btn btn-danger text-white p-1 rounded fw-bold'>{value}</span>
          )
      }
      else if (value=="confirmed")
          {
              return (
                  <span className='btn btn-primary text-white p-1 rounded fw-bold'>{value}</span>
              )
          }
          else if (value=="completed")
              {
                  return (
                      <span className='btn btn-success text-white p-1 rounded fw-bold'>{value}</span>
                  )
              }
  }
  return (
    <div className='container-fluid'>
    <Modal  className='admin_color rounded' isOpen={modal_status} size="xl" toggle={toggle}>
     <ModalHeader className='admin_color text-center w-100 ' toggle={toggle}>
     <h2 className='w-100 text-center fw-bold'> Appointment</h2>
     </ModalHeader>
     <ModalBody className='admin_color'>
      {isloading && <SimpleBackdrop/>}
         <div className='container-fluid p-0'>
         <div className='row'>
         <div className='col-sm-12 col-lg-12 mx-auto'>
         
          <div className='container-fluid w-100 p-1'>
            <div className='d-flex flex-column w-100 '>
              <div className='d-flex justify-content-center '>
                 <img src='../Assets/enku.jpg' style={{maxHeight:"200px"}} alt='barnd image' className='img-fluid mx-auto rounded-circle border border-3'/>
              </div>
              {account.role=="admin"?(
                              <div className='d-flex  justify-content-start w-100'><button onClick={toogle_notes_modal} className='btn btn-info'>Add Notes<Add/></button></div>
              ):(
                <></>
              )}

              <div className='d-flex  justify-content-end w-100'><button onClick={toggle_extra_modal} disabled={appointment?.status=="completed"?true:false} className='btn btn-primary'>Add more Treatments<Add/></button></div>
              <div className='d-flex justify-content-center mt-3 border-top p-2 '>
                <div className='d-flex  flex-column border rounded m-2 p-3'>
                  <div className='d-flex mt-2'> <h6 className='mx-3'>Name :</h6><h6 className='fw-bold '>{appointment?.name}</h6></div>
                  <div className='d-flex mt-2'> <h6 className='mx-3'>Email :</h6><h6 className='fw-bold '>{appointment?.email}</h6></div>
                  <div className='d-flex mt-2'> <h6 className='mx-3'>Phonenumber :</h6><h6 className='fw-bold '>{appointment?.phone}</h6></div>
                  <div className='d-flex mt-2'> <h6 className='mx-3 '>Appointment time :</h6><h6 className='btn btn-success p-1 fw-bold rounded text-white'>{appointment?.appointment_date}</h6></div>
                  <div className='d-flex mt-2'> <h6 className='mx-3 '>Treatments :</h6>
                  <div className='d-flex ' style={{flexWrap:"wrap"}}>
                  {treatments&&treatments.map((data)=>{
                      return(
                        <div className='d-flex rounded bg-white p-2 mx-3 mb-2'>
                        <span className='fw-bold'>{data.name} <button onClick={()=>handel_remove_treatment(data.id)} className='btn btn-danger p-0 mx-1' disabled={appointment?.status=="completed"?true:false}> <Close className=''/></button></span>
                        </div>
                      )
                    })}
                  </div>
                  </div>
                  <div className='d-flex mt-2'> <h6 className='mx-3 '>Duration :</h6><h6 className=' fw-bold '>
                  {
                    treatments&&treatments.map((data)=>{
                      duration=duration+data.duration;

                    })
                  }
                    {duration} Min</h6></div>
                  <div className='d-flex mt-2'> <h6 className='mx-3 '>Special requests :</h6><p className='fw-bold  '>{appointment?.special_request? appointment?.special_request:"Nothing"} </p></div>
                  <div className='d-flex mt-2'> <h6 className='mx-3'>Doctor :</h6><h6>{appointment?.doctor_name}</h6></div>
                  <div className='d-flex mt-2'> <h6 className='mx-3'>status :</h6><h6>{status_display(appointment?.status)}</h6></div>
                  <div className='d-flex mt-2'> <h6 className='mx-3'>Price :</h6><h6  className='btn btn-success p-1   fw-bold rounded text-white'>{
                    treatments&&treatments.map((data)=>{
                      price=price+parseFloat(data.price);
                    

                    })
                  }
                   <span>{nf.format(price)}</span>
                  </h6></div>
                  {appointment?.status=="completed"?<></>:
                      <div className='d-flex mt-4 w-100 '>
                      <form onSubmit={handelSubmit} className='w-100 '>
                      <div className="form-floating w-100 mb-3 mt-3">
            <select className="form-control" required  disabled={appointment?.status=="completed"?true:false} onChange={(e)=>setDoctor(e.target.value)}  value={selectedDoctor}name='doctor'>
                  <option value="">Select Doctor</option>
                  {
                    doctors && doctors.map((doctor,index)=>{
                      return(
                        <option key={index} value={doctor.id}> {doctor.name}</option>
                      )
                    })
                  }
                
            </select>
            <label htmlFor="Gender">Doctor</label>
       </div>
                         <div className="form-floating w-100 mb-3 mt-3">
                    <select className="form-control" disabled={appointment?.status=="completed"?true:false} onChange={(e)=>setMethod(e.target.value)} required value={Paymentmethod} name='role'>
                              <option value="">---Payment Method---</option>
                              <option value="cash">Cash</option>
                              <option value="mobile_payment">Mobile_payment</option>
                          
                          </select>
              <label htmlFor="title">Payment Method</label>
                  </div>
                  <div className='row p-3'>
                          <button type='submit'disabled={appointment?.status=="completed"?true:false}   className='btn btn-success fw-bold' >
                       Completed
                          </button>
                          </div>
                      </form>
                    </div>
                  }
              

                </div>
              </div>

            </div>

          </div>
         </div>
     </div> 
         </div>
     </ModalBody>
     <ModalFooter className='admin_color'>

<Button color="secondary" onClick={toggle}>
Done
</Button>
<AddExtra_modal reload={fetch_data}modal_status={extraModal} Modal_toggle={toggle_extra_modal} bookedTreatments={treatments} refresh_data={fetch_data} appointment_id={appointment?.appointment_id}/>
{account.role=="admin"?(<Addnotes modal_status={notesModal} Modal_toggle={toogle_notes_modal} appointment={appointment}/>):<></>}
</ModalFooter>
    </Modal>
 </div>
  )
}

export default Appointment_Detailpage